import React from 'react'
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from 'react-toastify';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { sendAPIRequest } from '../../../components/src/utils';
import { getStorageData } from '../../../framework/src/Utilities';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  filterMenuPosition: {
    top: number,
    left: number,
  } | undefined;
  filterRequestTypes: any[];
  filterRequestType?: string;
  filterCreatedByMe: boolean;
  filterStatus: string;
  selectingDate?: boolean;
  serveyTemplates:any;
  surveyId:any;
  deleteTemplteId:any;
  urlSearchParam:string;
  filterQuery:any;
  loadingSurveyTemplates:boolean;
  queryStr:string;
  pagination?: {
    next_page?: number;
  };
  filterByMe:boolean;
  filterByFav: boolean;
  communities: any[];
  requestors:any[];
  filterCommunities: any[];
  filterRequestors:any[];
  filterByStatus:any;
  deleteId:any;
  notFound:string;
  responseStatusStartingRange: number;
  responseStatusEndingRange: number;
  SurveyTitle:string;
  SurveyPath:string;
  descriptionSize:number;
  navData:boolean;
  SurveyType: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SurveysController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  filterRef?: any;
  surveyTemplateCallId : string='';
  templateDeleteCallId :string="";
  templatesFilterCallId:string="";
  apiPaginateTemplatesCallId:string="";
  taggedCommunitiesCallId:string="";
  filterSurveyTemplateCallId:string="";
  requestorsCallId:string="";
  path:string = this.props.navigation.history.location.pathname;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',
      filterMenuPosition: undefined,
      filterRequestTypes: [],
      filterRequestType: '',
      filterCreatedByMe: false,
      filterStatus: '',
      serveyTemplates:[],
      surveyId:'',
      deleteTemplteId:'',
      urlSearchParam:'',
      filterQuery:'',
      loadingSurveyTemplates:false,
      queryStr:'',
      filterByMe: false,
      filterByFav: false,
      communities: [],
      requestors:[],
      filterCommunities: [],
      filterRequestors:[],
      filterByStatus:'',
      deleteId:'',
      notFound:'',
      responseStatusStartingRange: 0,
      responseStatusEndingRange: 100,
      SurveyTitle:'',
      SurveyPath:'',
      descriptionSize:0,
      navData:false,
      SurveyType: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.filterRef = React.createRef()
    // Customizable Area End

  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const currentUser = await getStorageData('user');
    const userData = JSON.parse(currentUser);
    const { role: { role_level = 0 } = {}, user_type = '' } = userData ?? {};
    const hasAccess = role_level > 4 ;
    if(!hasAccess){
      let errorMessage = "Sorry, You do not have access to this page"
      let ActivityFeedLink = "ActivityFeed"
      toast.warn(errorMessage)
      this.getNavigationMessage(ActivityFeedLink,this.props)    
    }
    this.initializeSurveyListing();
  }

  initializeSurveyListing = async() => {
    let SurveyTitle = this.path === "/MandatorySurveys" ? "Mandatory Assessment Survey" : "Survey Templates"
    let SurveyPath = this.path === "/MandatorySurveys" ? "ReportingFrameworks" : "SurveyCards"
    let SurveyType = this.path === "/MandatorySurveys" ? true : false
    const screenSize = window.screen.width;
    const descriptionSize = this.calculateDescriptionSize(screenSize);
    const authToken = await getStorageData("authToken");
    if (authToken == null) {
      this.props.navigation.navigate('Home')
    } 
    this.setState({token: authToken, SurveyTitle, SurveyPath, descriptionSize, SurveyType}, () => {
      this.getSurveyTemplates()
      this.getRequestors()
      this.getCommunitiesByCreator()
    });
  }

  componentDidUpdate(prevProps:Props, prevState:S) {
    if (this.state.navData && !prevState.navData) {
        this.initializeSurveyListing();
    }
    if (this.state.navData) {
        this.setState({
            navData: false,
        });
    }
}
  // Customizable Area End

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.ReportingFrameworksNavData)
      );
      this.setState({ navData: data.navigated });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (responseJson){

      // convert else if ladder to switch case to resolved code smell without any logic changes 

        switch (apiRequestCallId) {
          case this.surveyTemplateCallId:
              this.surveyTemplateDataFun(responseJson)
            break;

          case this.apiPaginateTemplatesCallId:
              const res = [...this.state.serveyTemplates, responseJson.surveys.data].flat();
              this.setState({ serveyTemplates: res, pagination: responseJson.meta.pagination });
            break;

          case this.templateDeleteCallId:
            const dId = this.state.deleteTemplteId;
            const serveyTemplates = this.state.serveyTemplates.filter((p:any) => p.id != dId);
            this.setState({ serveyTemplates });
            break;

          case this.taggedCommunitiesCallId:
              const communities = responseJson.communities?.data?.map((item:any) => item?.attributes);
              this.setState({ communities });
            break;

          case this.requestorsCallId:
              const req = responseJson?.data?.map((item: any) => item?.attributes);
              const requestors = req?.map((item: any) => {
                let reqArr = { id: item?.id, name: `${item?.first_name} ${item?.last_name}` };
                return reqArr;
              });
              this.setState({ requestors });
            break;

          case this.filterSurveyTemplateCallId:
              this.filterDataFun(responseJson)
            break;
        }
      }
    }
    // Customizable Area End

  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // handleFilterMenuClose = () => {
  //   this.setState({ filterMenuPosition: undefined });
  //   this.filterRequests();
  // }

  clearFilters = (e?: React.MouseEvent) => {
    e && e.stopPropagation()
    this.setState({
      // filterFromDate: null,
      // filterToDate: null,
      filterByStatus: '',
      filterRequestType: '',
      // filterCreatedByMe: false,
      queryStr: '',
      filterCommunities:[],
      filterRequestors:[],
      loadingSurveyTemplates:true,
      responseStatusStartingRange: 0,
      responseStatusEndingRange: 100,
    })
    // load posts with no filters
    this.getSurveyTemplatesApi();
    // this.filterPosts()
  }

  filterText = () => {
    let filterText = "";
    // if (this.state.filterFromDate || this.state.filterToDate) {
    //   filterText += `Date(${this.state.filterFromDate?.toISOString()?.slice(0, 10) || '-'} to ${this.state.filterToDate?.toISOString()?.slice(0, 10) || '-'} ) `
    // }

    if(this.state.filterRequestors?.length) {
      const requ= this.state.filterRequestors.map(this.getrequestors)
      if(requ)filterText +=`Requested by: ${requ}`
    }
    if (this.state.filterCommunities?.length) {
      const tags = this.state.filterCommunities.map(this.getCommunitiesById)
      filterText += `Communities: ${tags.join(',')}`
    }


    if (typeof this.state?.filterStatus === "boolean") {
      filterText += `Status: ${this.state.filterStatus?"Complete":"Pending"}`
    }
    if (this.state.filterCreatedByMe) filterText += 'Created By Me'
    if (this.state.filterRequestType?.length) {
      const req = this.state.filterRequestTypes.find(
        (x:any) => x.id == this.state.filterRequestType
      )
      if(req)
        filterText += `Request Type: ${req?.name}`
    }

    return filterText
  }

  getrequestors = (id: number) => {
    const cat = this.state.requestors.find(c => c.id === id)
    if (cat) return cat.name
    return ""
  }

  handleRequestorSelect = (id: number) => () => {
    if (this.state.filterRequestors.indexOf(id) === -1) {
      this.setState({ ...this.getBlankFilters(), filterRequestors: [...this.state.filterRequestors, id] }, () => this.filterPosts())
    } else {
      this.setState({ ...this.getBlankFilters(), filterRequestors: this.state.filterRequestors.filter((x: number) => x !== id) }, () => this.filterPosts())
    }
  }

  // handleFilterMenuOpen = (e: React.MouseEvent) => {
  //   console.log('filterRef');
  //   console.log(this.filterRef);
  //   if (!this.filterRef) return;
  //   const box = this.filterRef?.current?.getBoundingClientRect();
  //   this.setState({
  //     filterMenuPosition: {
  //       top: box?.bottom,
  //       left: box?.left + 20
  //     }
  //   })
  // }

  handleRequestTypeSelect = (id: number) => (e: any) => {
    // console.log({id, filterRequestType: '469'})
    // e.preventDefault()
    // e.stopPropagation()
    // if (this.state?.filterRequestType!=id.toString()) {
    //   // add id
    //   this.setState({ 
    //     ...this.getBlankFilters(), 
    //     filterRequestType: id.toString() 
    //   }, () => this.filterRequests())
    // } else {
    //   // remove id
    //   this.setState({ 
    //     ...this.getBlankFilters(), 
    //     filterRequestType: '' 
    //   }, () => this.filterRequests())
    // }
  }

  handleDateFilterSubmit = () => {
    // this.filterRequests()
  }

  handleFromDateChange = (date: any) => {
    // this.setState({ ...this.getBlankFilters(), filterFromDate: date, filterToDate: this.state.filterToDate })
  }

  handleToDateChange = (date: any) => {
    // this.setState({ ...this.getBlankFilters(), filterToDate: date, filterFromDate: this.state.filterFromDate })
  }

  toggleFilterByMe = () => {
    this.clearFilters()
  }

  handleDeletePopup=(data:any)=>{
    if(data?.status == "live"){
      toast.warn("Can not delete Live Template");
    }
    else{
      this.setState({deleteId: data?.id})
    }
  }

  closeDeleteModal = () => {
    this.setState({deleteId: undefined})
  }

  handleFilterByStatus = (status:any) => {
    const filters = this.getBlankFilters()
    // filters['filterByStatus'] = '0'

    this.setState({ filterByStatus:status,  filterMenuPosition: undefined }, () => this.filterPosts())
  }

  handleFilterByResponseRange = (value: any, type: any) => {
    if(type === "start"){
      this.setState({...this.state, responseStatusStartingRange: value})
    }else if(type === "end"){
      this.setState({...this.state, responseStatusEndingRange: value})
    }
  }

  cancelResponseRange = () => {
    this.setState({ ...this.state, responseStatusStartingRange: 0, responseStatusEndingRange: 100, filterMenuPosition: undefined}, () => this.filterPosts())
  }

  applyResponseRange = () => {
    this.setState({... this.state, filterMenuPosition: undefined}, () => this.filterPosts())
  }

  handleDateInputFocus = () => {
    this.setState({ selectingDate: true })
  }
  handleDateInputBlur = () => (this.setState({ selectingDate: false }));

  onSelecteFilterStatus = (status: any) => (e: any) => {
    // e.preventDefault()
    // e.stopPropagation()
    // if (this.state?.filterStatus !== status) {
    //   // add id
    //   this.setState({ 
    //     ...this.getBlankFilters(), 
    //     filterStatus: status, 
    //   }, () => this.filterRequests())
    // } else {
    //   // remove id
    //   this.setState({ ...this.getBlankFilters(), filterStatus: '' }, () => this.filterRequests())
    // }
  }

  handleDateFilterCancel = () => {
    this.setState({ filterMenuPosition: undefined })
  }

  filterPosts = async() => {
    const queryStr = this.getFilterQueryParams().toString()
    // if (queryStr === this.state.queryStr) return
    this.setState({ queryStr, serveyTemplates: [], loadingSurveyTemplates: true })




    // const queryStr = this.getFilterQueryParams().toString()
    // if (queryStr === this.state.queryStr) return
    // this.setState({ queryStr, feedPosts: [] })
    // let baseUrl = configJSON.getFilterPostEndpoint
  
    // if (this.props.inside === "CommunityDetail")
    //   baseUrl = this.getCommunitPostsUrl();
    // const myUrl = this.getMyUrl()
    // if (myUrl) baseUrl = (this.props.inside === 'UserProfile' ? configJSON.getAllUserPostsEndpoint : configJSON.getAllPostsEndpoint) + myUrl
    if (queryStr) {
      const token= await getStorageData("authToken") || ''
      const frameWork = await getStorageData("reportingFrameworkId");
      this.filterSurveyTemplateCallId = sendAPIRequest(
        this.path === "/MandatorySurveys" ? 
        `${configJSON.getSurveyTemplates}?reporting_framework_id=${frameWork}&${queryStr}` : 
        `${configJSON.getSurveyTemplates}?${queryStr}`, {
            method: "GET",
            headers: { 'token': token },
        })
      // this.setState({ loadingSurveyTemplates: true })
    } else {
      this.getSurveyTemplates()
    }
  }

  loadNextPage = async() => {
    const token= await getStorageData("authToken") || ''
    const query = this.getFilterQueryParams()
    const isFiltersSet = query.toString().length > 0
    query.append('per_page', '6')
    query.append('page', String(this.state.pagination?.next_page))
    let baseUrl = isFiltersSet ? `${configJSON.getFilterPostEndpoint}` : configJSON.getSurveyTemplates
    const frameWork = await getStorageData("reportingFrameworkId");
    // const myUrl = this.getMyUrl()
    // if (myUrl)
      // baseUrl = configJSON.getAllPostsEndpoint + myUrl
    this.apiPaginateTemplatesCallId = sendAPIRequest(
      this.path === "/MandatorySurveys" ? 
      `${configJSON.getSurveyTemplates}?reporting_framework_id=${frameWork}&${query}` : 
      `${configJSON.getSurveyTemplates}?${query}`, {
        method:'GET',
        headers:{'token':token},  
    })
    this.setState({  })
  }

  getSurveyTemplates = async()=>{
    const token= await getStorageData("authToken") || ''
    const frameWork = await getStorageData("reportingFrameworkId");
    this.setState({loadingSurveyTemplates:true})
    const queryStr = this.getFilterQueryParams().toString()
    // if (queryStr === this.state.queryStr) return
    this.setState({ queryStr, serveyTemplates: [] })
    // const searchry=!!queryStr
    // let query = ""
    // if(queryStr) {
    //   query=`?${queryStr}`
    // }
    // debugger;
    this.surveyTemplateCallId = sendAPIRequest(
      this.path === "/MandatorySurveys" ? 
      `${configJSON.getSurveyTemplates}?reporting_framework_id=${frameWork}&${queryStr}&per_page=6` : 
      `${configJSON.getSurveyTemplates}?${queryStr}&per_page=6`, {
        method: "GET",
        headers: { 'token': token },
    })

    // this.surveyTemplateCallId = sendAPIRequest(`${configJSON.getSurveyTemplates}`, {
    //       method: "GET",
    //       headers: { 'token': this.state.token },
    //   })
}

getSurveyTemplatesApi=async()=>{
  const token= await getStorageData("authToken") || ''
  const frameWork = await getStorageData("reportingFrameworkId");
  this.surveyTemplateCallId = sendAPIRequest(
    this.path === "/MandatorySurveys" ? 
    `${configJSON.getSurveyTemplates}?reporting_framework_id=${frameWork}` : 
    `${configJSON.getSurveyTemplates}`, {
    method: "GET",
    headers: { 'token': token },
})
}

handleEditTemplate = (id:any)=>{
  const url = `${this.props.navigation.history.location.pathname}/EditSurveys/${id}`
  this.props.navigation.history.push(url)
}

handleDeleteTemplate = async(id:any) => {
  const token= await getStorageData("authToken") || ''
  this.templateDeleteCallId = sendAPIRequest(configJSON.deleteSurveyTemplate.replace(':id', id), {
    method: 'DELETE',
    headers: { token }
  })
  this.setState({deleteTemplteId:id, deleteId:''})
}

getCommunitiesById = (id: number) => {
  const cat = this.state.communities.find(c => c.id === id)
  if (cat) return cat.name
  return ""
}

getCommunitiesByCreator=async()=>{
  const id=this.context.id
  const authToken = await getStorageData("authToken");
  this.taggedCommunitiesCallId = sendAPIRequest(`${configJSON.getTaggedCommunities}?requested_by_id=${id}`, {
        method: 'GET',
        headers: {
            token: authToken,
            'content-type': 'appliction/json'
        },
    })
    this.setState({communities:[]})
}

getRequestors=async()=>{
  const authToken = await getStorageData("authToken");
  this.requestorsCallId = sendAPIRequest(`${configJSON.getRequestorsEndpoint}`, {
    method: 'GET',
    headers: {
        token: authToken,
        'content-type': 'appliction/json'
    },
})
}


onFilterQueryChange = (filterQuery: string) => {
  this.setState({ filterQuery }, () => this.getSurveyTemplates())
}

// onFilterChange = (filter: string) => {
//   const { token } = this.state
//   if(filter === this.state.filterQuery) return
//   this.setState({ filterQuery: filter })
//   if(filter)
//    {  console.log("filter value ", filter);
//       let url = configJSON.gerSurveyTemplateFilterSearchEndpoint
//       this.templatesFilterCallId = sendAPIRequest(`${url}?${filter}`, {method: 'GET', headers: { token } })
//       this.setState({ loadingSurveyTemplates: true, serveyTemplates: [] })
//   }
//   else 
//     this.getSurveyTemplates()
// }

handleCopyTemplates = (data:any)=>{
  const url = `${this.props.navigation.history.location.pathname}/CopySurveyTemplate/${data?.id}`
  this.props.navigation.history.push(url)
}
getBlankFilters = () => {
  return {
    // filterFromDate: null,
    // filterToDate: null,
    // filterCategories: [],
    filterCommunities: [],
    // filterByMe: false,
    // filterByFav: false,
    filterRequestors:[],
    filterByStatus:''
  }
}

// getFilterQueryParams = () => {
//   const query = new URLSearchParams()
//   if (this.state.filterQuery) query.append('query', this.state.filterQuery)
//   return query
// }

getFilterQueryParams = () => {
  const query = new URLSearchParams()
  // if (this.state.filterCategories.length) query.append('category_ids', this.state.filterCategories.join(','))
  if(this.state.filterRequestors.length) query.append('requested_by_ids', this.state.filterRequestors.join(','))
  if (this.state.filterCommunities.length) query.append('community_ids', this.state.filterCommunities.join(','))
  if (this.state.filterQuery) query.append('query', this.state.filterQuery)
  if (this.state.filterByStatus) query.append('status', this.state.filterByStatus)
  if (this.state.responseStatusStartingRange > -1 && this.state.responseStatusEndingRange > -1){
    query.append('from', String(this.state.responseStatusStartingRange));
    query.append('to', String(this.state.responseStatusEndingRange));
  }
  // if (this.state.filterByMe) query.append('created_by_me', this.state.filterByMe.toString())
  // if (this.state.filterByFav) query.append('favouriteable_type', 'BxBlockPosts::Post')
  
  return query
}
handleFilterMenuClose = () => {
  this.setState({ filterMenuPosition: undefined })
  // this.filterPosts()
}

handleFilterMenuOpen = (e: React.MouseEvent) => {
  if (!this.filterRef) return;
  const box = this.filterRef.current.getBoundingClientRect();
  this.setState({
    filterMenuPosition: {
      top: box.bottom,
      left: box.left + 20
    }
  })
}
getNavigationMessage = (route: string, props: any, payload?: any) => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), route);

  message.addData(getName(MessageEnum.NavigationPropsMessage), props);

  this.send(message);
}

handleTagSelect = (id: number) => () => {
  if (this.state.filterCommunities.indexOf(id) === -1) {
    this.setState({ ...this.getBlankFilters(), filterCommunities: [...this.state.filterCommunities, id] }, () => this.filterPosts())
  } else {
    this.setState({ ...this.getBlankFilters(), filterCommunities: this.state.filterCommunities.filter((x: number) => x !== id) }, () => this.filterPosts())
  }
}
calculateDescriptionSize(screenSize:number) {
  //Old code used which is pre configured 
  if (screenSize <= 1400) {
    return 35;
  } else if (screenSize <= 1550) {
    return 45;
  } else {
    return 50;
  }
}

filterDataFun(responseJson:any) {
  const filteredData = responseJson?.surveys?.data;
  const notFound = responseJson?.surveys;
  if (notFound == "No data found") {
    this.setState({ notFound: notFound, loadingSurveyTemplates: false });
  } else {
    this.setState({ serveyTemplates: filteredData, loadingSurveyTemplates: false });
  }
}

  surveyTemplateDataFun(responseJson: any) {
    this.setState({ loadingSurveyTemplates: false });
    if (!Array.isArray(responseJson?.surveys?.data)) return;
    const serveyTemplatesData = responseJson.surveys.data;
    this.setState({ serveyTemplates: serveyTemplatesData });
    this.setState({ pagination: responseJson.meta.pagination });
  }

// getCommunitiesById=()=>{

//   this.taggedCommunitiesCallId = sendAPIRequest(`${configJSON.getTaggedCommunities}?requested_by_id=${id}`, {
//     method: 'GET',
//     headers: {
//         token: localStorage.getItem('authToken'),
//         'content-type': 'appliction/json'
//     },
// })
// this.setState({communities:[]})
// }

// toggleFilterByFav = () => {
//   const filters = this.getBlankFilters()
//   filters['filterByFav'] = !this.state.filterByFav
//   this.setState({ ...filters,  filterMenuPosition: undefined, loadingSurveyTemplates: true }, () => this.getSurveyTemplates())
// }
  // Customizable Area End

}