import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Image,
  ScrollView,
  TouchableWithoutFeedback,
  Platform
} from "react-native";
import {Box, Paper, Popper, ClickAwayListener, MenuList} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import MailIcon from '@material-ui/icons/Mail';
import Divider from '@material-ui/core/Divider';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import MenuIcon from '@material-ui/icons/MenuOutlined';
import CloseIcon  from "@material-ui/icons/CloseOutlined";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MoreIcon from '@material-ui/icons/MoreVert';
import Hidden from '@material-ui/core/Hidden';
import {CorpLogo } from '../../blocks/email-account-login/src/assets';
import DraftsIcon from '@material-ui/icons/Drafts';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import RoomIcon from '@material-ui/icons/Room';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, styled, Theme, createStyles,withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import { terms_cond } from "../../blocks/Settings5/src/assets";
import { logout_arrow } from "../../blocks/Settings5/src/assets";
import NotificationBell from '../../blocks/DesktopNotifications2/src/NotificationBell.web'
import LeftMenuWeb from "./LeftMenuWeb";

import DashboardController, { Props } from "../../blocks/dashboard/src/DashboardController";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { RFValue } from "react-native-responsive-fontsize";
import moment from "moment";
import { CloseButton } from "./FormModal.web";
const profileAvatar = require('./profile_avatar.png')

const drawerWidth = 240;

// Customizable Area End
export default class TopNavBar extends DashboardController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
 
  // Customizable Area End
    
  render() {
     // Customizable Area Start
    //@ts-ignore
    const {navigation}= this.props
    const menuId = 'primary-search-account-menu';
    const mobileMenuId = 'primary-search-account-menu-mobile';
    console.log({navigation})
    const matchPath = (url: string)=>window?.location?.pathname?.indexOf?.(url) > -1
    // Customizable Area End
    return (
      <>
      <LeftSideBar open= {this.state.drawerOpen} variant="persistent" anchor="left"
      >
        <LeftMenuWeb onCloseSidebar={this.handleCloseDrawer} navigation={navigation} id={""} />
        <CloseButton style={{top:32}}> <CloseIcon onClick={this.handleCloseDrawer} /> </CloseButton>
      </LeftSideBar>

      <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
      {/* Required for all blocks */}
      <TouchableWithoutFeedback
        testID={"Background"}
        onPress={() => {
          this.hideKeyboard();
        }}
      >
        {/* Customizable Area Start */}
        {/* Merge Engine UI Engine Code */}
      
      <View >
     
      <Wrapper>
      <TopRight>
      <ResponsiveMenu >
      <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge color="secondary">
            <MenuIcon onClick={this.handleDrawer} />
          </Badge>
        </IconButton> 
      </ResponsiveMenu>
      
      <Box style={{backgroundColor:"white", }}>
        <img src ={CorpLogo} width="120px" height="40px" onClick={()=>{navigation.navigate("AllActivityFeed")}}/>           
      </Box>
      </TopRight>
      <div>
      <Menu
      anchorEl={this.state.mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(this.state.mobileMoreAnchorEl)}
      onClose={this.handleMobileMenuClose}
    >
      
      <MenuItem style={{justifyContent:'center'}}>
      <NotificationBell navigation={this.props.navigation} id="" />
        {/* <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsNoneIcon />
          </Badge>
        </IconButton> */}
        {/* <p>Notifications</p> */}
      </MenuItem>
      {/* <NotificationBell navigation={this.props.navigation} id="" /> */}
      {/* <MenuItem onClick={this.handleProfileMenuOpen}>
      <Avatar style={{width:"46px", height:"46px",marginRight:"10px",marginLeft:"10px"}} >    
        </Avatar>
        <p>Profile</p>
      </MenuItem> */}
      <Divider />
      
      <MenuItem disabled={!(this.context?.role?.role_level > 4)} onClick={()=>{navigation.navigate("ImpactDashboard")}}>
        <ListItemIcon>
            <PeopleAltOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </MenuItem>

      <MenuItem disabled={!(this.context?.role?.role_level > 4)} onClick={()=>{navigation.navigate("JobListing2")}}>
        <ListItemIcon>
            <PeopleAltOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Project Management" />
      </MenuItem>

      <MenuItem onClick={()=>{navigation.navigate("AllActivityFeed")}}>
        <ListItemIcon>
            <PeopleAltOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Posts" />
      </MenuItem>
      <MenuItem onClick={()=>{navigation.navigate("all_project_end_user")}}>
        <ListItemIcon>
            <PeopleAltOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Projects" />
      </MenuItem>
      <MenuItem onClick={()=>{navigation.navigate("JobListing2")}}>
        <ListItemIcon>
            <PeopleAltOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Marketplace" />
      </MenuItem>
      <MenuItem onClick={()=>{navigation.navigate("CommunityWeb")}}>
        <ListItemIcon>
            <PeopleAltOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Communities" />
      </MenuItem>
      
    </Menu>
      </div>

      <Hidden smDown>
        <div style={{display:"flex"}}>
        {<StyledChip disabled={!(this.context?.role?.role_level > 4)} label="Dashboard" variant='outlined' selected={matchPath('/ImpactDashboard')}  onClick={()=>{navigation.navigate("ImpactDashboard")}} />}
        {<StyledChip disabled={!(this.context?.role?.role_level > 4)} label="Project Management" variant='outlined' selected={matchPath('/ProjectManagement')}  onClick={()=>{navigation.navigate("ProjectManagement")}} />}
        <StyledChip label="Posts" variant='outlined' selected={matchPath('/AllActivityFeed')}  onClick={()=>{navigation.navigate("AllActivityFeed")}} />
         <StyledChip label="Projects" variant='outlined' selected={matchPath('/all_project_end_user')}  onClick={()=>{navigation.navigate('ProjectEndUser')}}  />
         <StyledChip selected={matchPath('/Marketplace')} onClick={()=>{navigation.navigate("JobListing2")}} label="Marketplace" variant='outlined'  />
         <StyledChip label="Communities" variant='outlined' selected={matchPath('/CommunityWeb')}  onClick={()=>{navigation.navigate("CommunityWeb")}}  
        />
        </div>
      
      </Hidden>
      <div>
    <StyledPopper 
      open={Boolean(this.state.test1)}
      anchorEl={this.state.test1}
      role={undefined}
      transition
      placement="bottom-start"
    >
      <Paper>
        <ClickAwayListener onClickAway={this.handleMenuClose}>
          <MenuList style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', padding: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 5px', width: '100%' }}>
              <ListSubheader style={{ fontWeight:800, color:"#000000",fontSize:"14px", fontFamily:"Helvetica", paddingLeft: '5px'}}>ACCOUNT</ListSubheader>
            </div>
            <MenuItem style={{padding:"10px 50px 10px 20px",color:"#4F4F4F", fontFamily:"Helvetica"}} onClick={()=>{navigation.navigate('SettingsWeb')}}>
                  <AccountCircleOutlinedIcon height="18px" style={{color:"grey", marginRight:"15px"}}/>
                  My Profile
            </MenuItem>
            <MenuItem style={{padding:"10px 50px 10px 20px",color:"#4F4F4F", fontFamily:"Helvetica"}} onClick={()=>{navigation.navigate('TermsWeb')}}>
                  <img src={terms_cond} style={{height: 20,marginRight:"15px"}}/>   
                  Terms & Conditions
            </MenuItem>
            <MenuItem style={{padding:"10px 50px 10px 20px",color:"#4F4F4F", fontFamily:"Helvetica" }} onClick={()=>{navigation.navigate('HelpWeb')}}>
                <LiveHelpOutlinedIcon fontSize="small" style={{color:"grey",marginRight:"15px"}}/>
                Help & Support
            </MenuItem>
            <MenuItem style={{padding:"10px 50px 10px 20px",color:"#4F4F4F", fontFamily:"Helvetica"}} onClick={()=>{navigation.navigate('ChangePasswordWeb')}}>
                <LockOutlinedIcon fontSize="small" style={{color:"grey",marginRight:"15px"}}/>
                Change Password
            </MenuItem>
            <MenuItem style={{padding:"10px 50px 10px 20px",color:"#4F4F4F", fontFamily:"Helvetica"}}
            onClick={this.handleLogout}
            >
              < img src={logout_arrow} style={{height: 20,marginRight:"15px"}}/> 
                Logout
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </StyledPopper>
    
      </div>
      
        <TopLeft>
      <Hidden mdUp>
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={this.handleMobileMenuOpen}
            style={{color:"grey"}}
          >
            <MoreIcon />
          </IconButton>
        </Hidden>
        <MobileProfileMenu
           onClick={this.handleProfileMenuOpen}
           >
              <Avatar>
              <img src={ this.context.profile_pic? this.context.profile_pic:this.state.profile_pic?this.state.profile_pic:profileAvatar}alt="img" width="100%" height="100%" />
              </Avatar>
            <ArrowDropDownIcon style={{color:"grey", marginTop:"5px"}}/>
          </MobileProfileMenu>
          </TopLeft>
          <Hidden smDown>
         <div style={{display:"flex"}}>
          <NotificationBell navigation={this.props.navigation} id="" />
          <div style={{display:"flex", marginLeft:"10px", cursor:"pointer"}} 
           onClick={this.handleProfileMenuOpen}
           >
              <Avatar>
              <img src={ this.context.profile_pic? this.context.profile_pic:this.state.profile_pic?this.state.profile_pic:profileAvatar}alt="img" width="100%" height="100%" />
              </Avatar>
            <ArrowDropDownIcon style={{color:"grey", marginTop:"5px"}}/>
          </div>
         </div>
        </Hidden>
      </Wrapper>
   
    </View>
       
          {/* Merge Engine UI Engine Code */}
          {/* Customizable Area End */}
        </TouchableWithoutFeedback>
      </ScrollView>
      </>
    );
  }
}

// Customizable Area Start

const Wrapper = styled('div')({
  display:"flex",
  justifyContent:"space-between",
  padding:"15px 40px  10px 40px",
  backgroundColor:"white",
  '@media screen and (max-width: 820px)': {
    padding:"15px 0px  10px 0px",
  },
})

const TopRight = styled('div')({
  display: "flex",

})

const TopLeft = styled('div')({
  display: "flex",
  
})

const MobileProfileMenu = styled('div')({
  display:"none", 
  // marginLeft:"10px", 
  cursor:"pointer",
  '@media screen and (max-width: 959px)': {
    display:"flex",
  },
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: "center",
    display:"flex",
    width: "100%",


  },
  view:{
    display:"flex"
  }
  
});
const StyledChip = withStyles((theme) => ({
  root: {
    color: (props: any) => props.selected?"#1ea60a":"#858585",
    height:"30px",
    margin:"5px 5px 0px 5px",
    padding:"5px 10px",
    fontWeight:"bolder",
    border: (props: any) => props.selected?"1px solid #1ea60a":"1px solid rgba(0,0,0,0.23)",
    backgroundColor: (props: any) => props.selected?"#FFF":"#f2f2f2",
    
    '&:focus': {
      backgroundColor:"#dafcd4",
      color: "#1ea60a",
      border:"1px solid #1ea60a",
      '& .MuiChip-root': {
        color: "#1ea60a",
        fontWeight:"bold",
        backgroundColor:"#dafcd4",
      },
    },
},
}))(Chip);

const ResponsiveMenu = styled(Box)({
    display: 'none',
    '@media screen and (max-width: 959px)': {
      display: 'flex',
  }
})

const StyledPopper = styled(Popper)({
  maxHeight: '80vh',
  overflowY: 'auto',
  marginLeft:'20px',
  overflowX: 'hidden',
  boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px',
  zIndex:2
});

const LeftSideBar = styled(Drawer)({
  top: 0,
  Left: 0,
  width: '300px',
})
// Customizable Area End
