import React from "react";
// Customizable Area Start
import PeopleManagementController, { Props } from "./PeopleManagementController";
import {
	Popper,ClickAwayListener,
	Grid, TextField, Checkbox, ListItemText, Chip, Fade, Backdrop, Modal, Paper, Divider, Box, Typography, MenuItem, Hidden, Button, InputAdornment, Menu, TableContainer, Table, TableHead, TableRow, TableCell, TableBody
} from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { image_preference } from "../../email-account-registration/src/assets";
import { styled } from '@material-ui/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Flex from "../../../components/src/Flex.web";
import OverlayLoader from '../../../components/src/Loader.web'
import { Formik } from "formik";
import { InputRow, MCountedTextArea, MDatePicker } from "../../../components/src/FormModal.web";
import { MInput, MSelect} from "../../CfCourseListing5/src/Components.web";
import Pagination from '@material-ui/lab/Pagination';
import moment from "moment";
import UserContext from "../../../components/src/UserProvider";
import { iconAdd } from "../../JobListing2/src/assets";
import { CloseCircleIcon } from "../../../components/src/Icons.web";
import { ArrowDownIcon, warningIcon } from "./assets";
import TopNavBar from "../../../components/src/TopNavbar";
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import Maps from "../../maps/src/Maps.web";
import Search from "../../../components/src/Search.web";
import NestedMenuItem from "../../../components/src/NestedMenuItem.web";
import { useWindowSize } from "../../../components/src/utils";
// Customizable Area End

export default class PeopleManagement extends PeopleManagementController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

  	// Customizable Area Start
	 showActiveSubscriptionPackage = () => {
		if(this.state.subscriptionDetails?.subscription_name !== undefined && this.state.subscriptionDetails?.subscription_name!==null){
			return `${this.state.subscriptionDetails?.subscription_name}(Corporate)`
		}
		else {
			return '-'
		}
	}

	 PmLicensedUser = () => {
		if(this.state.subscriptionDetails?.remain_corp_licences_count!==undefined && this.state.subscriptionDetails?.remain_corp_licences_count!==null){
			return this.state.subscriptionDetails?.remain_corp_licences_count
		}
		else {
			return '-'
		}
	}

	 IndividualUser = () => {
		if(this.state.subscriptionDetails?.remain_individual_licences_count!==undefined && this.state.subscriptionDetails?.remain_individual_licences_count!==null){
			return this.state.subscriptionDetails?.remain_individual_licences_count
		}
		else {
			return '-'
		}
	}

	 startDate = () => {
		if(this.state.subscriptionDetails?.start_date !== undefined && this.state.subscriptionDetails?.start_date !== null){
			return moment(this.state.subscriptionDetails?.start_date).format("DD/MM/YYYY") 
		}
		else {
			return '-'
		}
	}

	 endDate = () => {
		if(this.state.subscriptionDetails?.end_date !== undefined && this.state.subscriptionDetails?.end_date!==null){
			return moment(this.state.subscriptionDetails?.end_date).format("DD/MM/YYYY")
		}
		else {
			return '-'
		}
	}

	handleFilterQueryChange = (e: any) => {
		let filterValue: any = "";
		switch (this.state.filterText) {
		  case "Environment":
			filterValue = 1;
			break;
		  case "Social":
			filterValue = 2;
			break;
		  case "Governance":
			filterValue = 3;
			break;
		  case "Active":
			filterValue = "yes";
			break;
		  case "Inactive":
			filterValue = "no";
			break;
		}
		this.onFilterQueryChange(e, filterValue);
	  }

	PeopleManagementTabs() {
		const open = Boolean(this.state.anchorEl);

		return <>
			<CoinsHead>
				People Management
			</CoinsHead>
			<CorpCoins>
				<Flex col>
					<Typography style={{ whiteSpace: 'nowrap' }}>Active Subscription Package</Typography>
					<TC>{this.showActiveSubscriptionPackage()}</TC>
				</Flex>
				<Divider orientation="vertical" flexItem />
				<Flex col>
					<Typography style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>PM / Corporate Licence Users</Typography>
					<TC>{this.PmLicensedUser()}</TC>
				</Flex>
				<Divider orientation="vertical" flexItem />
				<Flex col>
					<Typography style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Individual Users</Typography>
					<TC>{this.IndividualUser()}</TC>
				</Flex>
				<Divider orientation="vertical" flexItem />
				<Flex col>
					<Typography style={{ whiteSpace: 'nowrap' }}>Start Date</Typography>
					<TC>{this.startDate()}</TC>
				</Flex>
				<Divider orientation="vertical" flexItem />
				<Flex col>
					<Typography style={{ whiteSpace: 'nowrap' }}>End Date</Typography>
					<TC>{this.endDate()}</TC>
				</Flex>
			</CorpCoins>
			<PageWrapper>
				<CenterRow>
				<Grid container justifyContent="center" style={{ margin: '30px 0px 25px 0px' }}>						<Grid item >
							<RoundedButtonBase>
								<CorporateLicenceTab
									id='showCorporateLicenseUser'
									onClick={(e) => this.chooseCorporateLicenseUser(e)}
									style={this.state.activeChooseButton ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}
								>PM / CORPORATE LICENCE USER</CorporateLicenceTab>
								<IndividualUserTab
									id='showIndividualLicenseUser'
									onClick={(e) => { this.chooseIndividualUser(e) }}
									style={this.state.activeIndividualUserButton ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}
								>INDIVIDUAL USER</IndividualUserTab>
								<PendingLicenceUserTab
									id='showPendingLicenseUser'
									onClick={(e) => this.choosePendingLicenceReq(e)}
									style={this.state.activePendingLicenceRequestButton ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}
								>PENDING LICENCE REQUESTS</PendingLicenceUserTab>
							</RoundedButtonBase>
						</Grid>
						<Grid style={{ width: '100%', marginTop: '25px', justifyContent: 'space-between', display: 'flex' }}>
							<Flex style={{ width: "30%" }}>
								<Search data-test-id={'searchInput'} searchInputValue={this.state.searchInputValue} onChange={this.handleFilterQueryChange} />
							</Flex>
							<Flex style={{ display: "flex", gap: '10px', }}>
								<div style={{ flex: 1 }}>
									<TextFieldRounded
										data-test-id='openFilter'
										value={this.state.filterText}
										onClick={this.handleFilterMenuOpen}
										// @ts-ignore 
										InputProps={{
											ref: this.filterRef,
											endAdornment: (<InputAdornment position="start">
												{this.state.filterText && <CloseCircleIcon
													onClick={this.clearFilters}
													style={{ margin: '0 10px', width: '24px', cursor: 'pointer' }}
												/>}
												<img src={ArrowDownIcon} width="16" />
											</InputAdornment>)
										}} variant="outlined" placeholder={"Filter by:"} />
									{/* @ts-ignore */}
									<Hidden mdDown>
										<FilterMenu
											data-test-id='filterMenu'
											menuPosition={this.state.filterMenuPosition}
											onClose={this.handleFilterMenuClose}
											filterByAll={this.state.filterByAll}
											// filterByStatus={this.state.filterByStatus}
											filterByPrefrence={this.state.filterByPrefrence}
											toggleFilterByAll={this.toggleFilterByAll}
											// toggleFilterByStatus={this.toggleFilterByStatus}
											toggleFilterByPrefrence={this.toggleFilterByPrefrence}
										/>
									</Hidden>
								</div>
								<RoundedButtonNewUSer
									id='AddUser'
									onClick={this.changeModalScreen}
									variant="contained"
									disableElevation
									startIcon={<img src={iconAdd} width={30} style={{ marginLeft: '-5px' }} />}>
									Add New User
								</RoundedButtonNewUSer>
							</Flex>
						</Grid>
					</Grid>
					{(this.state.corporateLicenseUser || this.state.individualUser || this.state.pendingLicenceReq) &&
						<CorpsTable>
							<TableContainer component={Paper} >
								{this.state.loading ? <LoaderContainer>Loading...</LoaderContainer> :
									<Table style={{ minWidth: 650 }} aria-label="simple table">
										<TableHead>
											<TableRow>
												<TH>S.No</TH>
												<TH style={{ whiteSpace: 'pre', }}>Name</TH>
												<TH>Email</TH>
												<TH>Preferences</TH>
												<TH>Location</TH>
												<TH>PM/Company Name</TH>
												<TH>Designation</TH>
												<TH>Role</TH>
												<TH></TH>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.state.peopleManagementData?.length > 0 ? this.state.peopleManagementData?.map((value, index) => {
												const { email, first_name, last_name, preference, location, company_name, designation } = value?.attributes?.requester_account?.data?.attributes
												return (
													<TableRow>
														<TR>{email && (10 * (this.state.current_page - 1) + (index + 1))}</TR>
														<TR style={{ whiteSpace: 'pre', }}>{first_name} {last_name}</TR>
														<TR>{email}</TR>
														<TR>{preference.toString()} </TR>
														<TR>{location}</TR>
														<TR>{company_name}</TR>
														<TR style={{ wordBreak: "break-word" }}>{designation!==null ? designation : ""}</TR>
														<TR>{value?.attributes?.requester_account?.data?.attributes.role?.name ?? ""}</TR>
														{!this.state.pendingLicenceReq &&
															<>
																<TR style={{ cursor: "pointer", paddingLeft: "30px", paddingRight: "30px" }} data-test-id='openMenu' onClick={(e) => this.handleClick(e, value.id)}>...</TR>
																{this.state.table_row_id === value?.id && <MouseOverPopover handleDeleteUser={this.handleDeleteUser} editUSer={this.editUser} value={value} open={open} anchorEl={this.state.anchorEl} handleClose={this.handleClose} />}
															</>}
														{email && (this.state.corporateLicenseUser || this.state.individualUser) &&
														this.state.table_row_id === value?.id && <UserModal DeleteRow={this.DeleteRow} rejectModal={false} value={value} showModal={this.state.showDeleteModal} handleModal={this.handleDeleteUser} updatePeopleManagementListing={this.updatePeopleManagementListing} />
														}
														{email && this.state.pendingLicenceReq &&
															<>
																<TR>
																	<Flex gap={8}>
																		<Action color="#00A100" style={{ cursor: "pointer" }} id='approveUser' onClick={() => this.changeToApproveUser(value)}>Approve</Action>
																		<Action color="red" style={{ cursor: "pointer" }} id='rejectUser' onClick={()=>this.handleModal(value)}>Reject</Action>
																	</Flex>
																</TR>
																{this.state.table_row_id === value?.id && <UserModal DeleteRow={this.DeleteRow
																} rejectModal={true} value={value} showModal={this.state.openModal} handleModal={this.handleModal} updatePeopleManagementListing={this.updatePeopleManagementListing} />}
															</>
														}
													</TableRow>
												)
											}) : <TableRow>
												<TR colSpan={9}>No Data Found</TR>
											</TableRow>}
										</TableBody>
									</Table>}
							</TableContainer>
							<Pagin>
								{window.screen.width > 950 ?
									<Pagination disabled={this.state.total_count <= 1} defaultPage={1} color="primary" variant="outlined" count={this.state.total_count} page={this.state.pages} onChange={this.handlePageChange} />
									:
									<Pagination disabled={this.state.total_count <= 1} defaultPage={1} color="primary" size="small" count={this.state.total_count} page={this.state.pages} onChange={this.handlePageChange} />
								}
							</Pagin>
						</CorpsTable>
					}
				</CenterRow>
			</PageWrapper>
			<Errormodal message={this.state.errMessage} handleErrPopover={this.handleErrPopover} errPopover={this.state.errPopover} />
		</>
	}

	ApproveUser() {
		const data= this.user?.attributes?.requester_account?.data?.attributes

		let userTypeValue='';
		if(data?.role?.name!==null && data?.role?.name!==undefined  && (data?.role?.name=="Individual User" || data?.role?.name=="Individual User ")){
			userTypeValue='individual_user'
		}
		else if(data?.role?.name!==null && data?.role?.name!==undefined && (data?.role?.name=="Corporate Plus" || data?.role?.name=="Corporate")){
			userTypeValue='licensed_user'
		}
		return <AddUserBox>
			<AddUserHead>
				APPROVE USER
			</AddUserHead>
			<Divider style={{marginBottom: '20px'}} light={true} />
			<Formik
				initialValues={{
					id: this.user?.id,
					first_name: data?.first_name,
					last_name: data?.last_name,
					email: data?.email,
					location: data?.location,
					company_name: data?.company_name,
					designation: data?.designation,
					prefrence: data?.preference,
					about: data?.about,
					subscription_date: this.state.subscriptionDetails?.end_date,
					user_type:userTypeValue,
				}}
				onSubmit={(values) => {
					this.updatePeopleManagementListing(values?.id, values?.user_type, values?.location, values?.designation, values?.about, 'approved', 'yes', values?.prefrence, values?.first_name, values?.last_name)
				}}
			>
				{({
					handleSubmit,
					isSubmitting,
				}) => (
					<form style={{maxWidth: '650px', margin: '0 auto'}} onSubmit={handleSubmit}>
						<AddUserWrapper className="tt">
							<InputRow label="" input={
								<Flex gap={10} style={{ width: '100%' }}>
									<InputRow style={{ width: '100%' }} label="First Name*" input={
										<MInput style={{backgroundColor: '#eee'}} name="first_name" placeholder="" disabled />
									}
									/>
									<InputRow style={{ width: '100%' }} label="Last Name*" input={
										<MInput style={{backgroundColor: '#eee'}}  name="last_name" placeholder="" disabled />
									} />
								</Flex>
							} />
							<InputRow label="Email*" style={{width:'100%'}} input={
								<MInput style={{backgroundColor: '#eee'}}  name="email" placeholder="" disabled />
							} />

							<InputRow label="Preferences*"  input={
								<MInput style={{backgroundColor: '#eee'}}  name="prefrence" disabled placeholder="" />
							} />

							<InputRow label="Location*" input={<MInput style={{backgroundColor: '#eee'}} name="location" placeholder="" disabled />} />

							<InputRow label="PM/Company Name" input={
								<MInput style={{backgroundColor: '#eee'}} name="company_name" disabled placeholder="" />
							} />
							<InputRow label="Designation"  input={
								<MInput style={{backgroundColor: '#eee'}}  name="designation" placeholder="" disabled />
							} />
							<FlexUser style={{backgroundColor: 'rgb(7, 188, 120)', padding: '10px'}}>
								<InputRow style={{width: '100%'}} label="User Types*" input={
									<MSelect name="user_type" placeholder="Select">
										<MenuItem disabled value={''}>Select</MenuItem>
										<MenuItem value={'licensed_user'}>PM / Corporate Licence User</MenuItem>
										<MenuItem value={'individual_user'}>Individual User</MenuItem>
									</MSelect>
								} />
							</FlexUser>
							<InputRow label="Description" input={
								<MCountedTextArea  name="description" placeholder="Write a description" disabled />
							} />
							<InputRow label="Subscription end date" input={
								<MDatePicker  style={{backgroundColor: '#eee'}}  name="subscription_date" disabled InputProps={{ placeholder: "Enter Date" }} disablePast />
							} />
							<Flex justify="center" align="center" gap={10} style={{marginTop: '10px'}}>
								<SubmitBtn
									variant="contained"
									bcolor='#e0e0e0'
									tcolor='#fff'
									onClick={this.closeModal}
								>
									CANCEL
								</SubmitBtn>
								<SubmitBtn
									type="submit"
									variant="contained"
									bcolor='#009c05'
									tcolor='#fff'
								>
									APPROVE
								</SubmitBtn>
							</Flex>
							<Errormodal message={this.state.errMessage} handleErrPopover={this.handleErrPopover} errPopover={this.state.errPopover} />
						</AddUserWrapper>
					</form>
				)}
			</Formik>
		</AddUserBox>
	}

	AddNewUser() {
		const { navigation } = this.props
		const { subscriptionDetails } = this.state
		const isDisabledPmLicensedOption=  !subscriptionDetails?.remain_corp_licences_count && subscriptionDetails?.remain_corp_licences_count<=0
		const isDisabledIndividualOption=  !subscriptionDetails?.remain_individual_licences_count && subscriptionDetails?.remain_individual_licences_count<=0
		
		return <AddUserBox>
			<AddUserHead>
				ADD NEW USER
			</AddUserHead>
			<Divider light={true} />
			<Formik
				initialValues={{
					prefrence: this.state.prefrenceData,
					first_name: '',
					last_name: '',
					email: '',
					location: '',
					designation: '',
					description: '',
					user_type: '',
					password: '',
					subscription_date: this.state.subscriptionDetails?.end_date,
					company_name: this.context.company_name
				}}
				validate={values => {
					const errors: any = {};
					if (!/^[a-zA-Z\s]*$/.test(values.first_name)) {
						errors.first_name = 'Invalid name, numbers cannot be accepted';
					}  if (!/^[a-zA-Z\s]*$/.test(values.last_name)) {
						errors.last_name = 'Invalid name, numbers cannot be accepted';
					}  if (!values?.email) {
						errors.email = 'Required';
					}  if (!values?.password) {
						errors.password = 'Required';
					}  if (!values?.first_name) {
						errors.first_name = 'Required';
					} if (!values?.last_name) {
						errors.last_name = 'Required';
					} 
					if (!values?.location) {
						errors.location = 'Required';
					} 
					if (!values?.user_type) {
						errors.user_type = 'Required';
					}  
					if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
						errors.email = 'Invalid email address';
					}  
					if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/i.test(values.password)) {
						errors.password = 'Password must conatin atleast 6 characters,a capital letter,a special character and a number';
					}  
					if (!/^[\s\S]{0,1000}$/i.test(values.description)) {
						errors.description = 'Character length should be in between 0-1000';
					}  
					if (this.state.prefrenceData?.length<=0) {
						errors.prefrence = 'Required';
					}
					return errors;
				}}
				onSubmit={(values) => {
					this.createUser(values)
				}}
			>
				{({
					handleSubmit,
					isSubmitting,
					values,
					handleChange,
					setFieldValue
				}) => (
					<FormAdd onSubmit={handleSubmit}>
						<AddUserWrapper className="tt">
							<InputRow label="" input={
								<Flex gap={10} style={{ width: '100%' }}>
									<InputRow style={{ width: '100%' }} label="First Name*" input={
										<MInput name="first_name" placeholder="" onChange={handleChange}  value={values.first_name}/>
									}
									/>
									<InputRow style={{ width: '100%' }} label="Last Name*" input={
										<MInput name="last_name" placeholder="" onChange={handleChange}  value={values.last_name} />
									} />
								</Flex>
							} />
							<InputRow label="Email*" input={
								<MInput name="email" placeholder="" onChange={handleChange}  value={values.email}/>
							} />
							<FieldSetWrapper xs={12} >
								<FieldLabel
									variant="subtitle2"
									component="div"

								>
									Preference <sup>*</sup>
								</FieldLabel>
								<Box style={{
									margin: "10px 12px",
									marginRight: '0px'
								}}>

									<MSelect
										onChange={(e) => this.handleChangePreference(e,setFieldValue)}
										id="choosePreference"
										name="prefrence"
										multiple
										displayEmpty
										fullWidth
										renderValue={(selected) => (
											<Box style={{ display: "flex", }}>
												<img src={image_preference} width="20px" height="15px" style={{ borderRadius: "10px", paddingTop: "4px", marginRight: "5px" }} />
												<Typography style={{ fontSize: "14px", margin: "0px", padding: "0" }} component={'span'}>
													Select
												</Typography>
											</Box>)
										}

										MenuProps={{
											anchorOrigin: {vertical: "bottom",horizontal: "left",},
											getContentAnchorEl: null,
											transformOrigin: {
												vertical: "top",
												horizontal: "left"
											},
										}}
									>
										<MenuItem 
										style={{
											height: "50px",padding: "20px 20px",
										}} key="E" value="Environment">
											<Checkbox
												icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
												checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
												checked={values?.prefrence?.indexOf("Environment") > -1}
												 />
											<ListItemText disableTypography primary={
												<div style={{ display: "flex", justifyContent: "space-between" }}>
													<Typography>
														E
													</Typography>
													<Typography>
														#Environment
													</Typography>
													<Typography>

													</Typography>
												</div>} />
										</MenuItem>

										<MenuItem style={{
											height: "50px",
											padding: "20px 20px",
										}} key="S" value="Social">
											<Checkbox
												icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
												checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
												checked={values?.prefrence?.indexOf("Social") > -1} 
												/>
											<ListItemText disableTypography primary={
												<div style={{ display: "flex", justifyContent: "space-between" }}>
													<Typography>
														S
													</Typography>
													<Typography style={{ justifyContent: "start", marginRight: "45px" }}>
														#Social
													</Typography>
													<Typography>

													</Typography>

												</div>} />
										</MenuItem>
										<MenuItem style={{
											height: "50px",
											padding: "20px 20px",
										}} key="G" value="Governance">
											<Checkbox
												icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
												checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
												checked={values?.prefrence?.indexOf("Governance") > -1} 
												/>
											<ListItemText disableTypography primary={
												<div style={{ display: "flex", justifyContent: "space-between" }}>
													<Typography>
														G
													</Typography>
													<Typography>
														#Governance
													</Typography>
													<Typography>

													</Typography>
												</div>} />
										</MenuItem>
									</MSelect>
								</Box>
								{values.prefrence?.map((prefrence: any) => {
									let value=prefrence
									if(prefrence=='1'){
										value= 'Environment'
									}
									if( prefrence=='2'){
										value= 'Governance'
									}
									if(prefrence=='3'){
										value= 'Social'
									}
									return <Chip
										key={value}
										label={value}
										onDelete={this.handleDelete(value,setFieldValue)}
										style={{ margin: "5px", marginLeft: '10px' }}
									/>
								})}
							</FieldSetWrapper>
							 {/* @ts-ignore */}
							<InputRow label="Location*" name="location" onChange={handleChange}  value={values.location} input={<><Maps autoCompleteStyles={undefined} customBorder={""} {...this.props} input  /></>} />

							<InputRow  label="PM / Company Name" input={
								<MInput name="company_name" disabled placeholder="" />
							} />
							<InputRow label="Password*" input={
								<MInput name="password" placeholder="" onChange={handleChange}  value={values.password}/>
							} />
							<InputRow label="Designation" input={
								<MInput name="designation" placeholder="" onChange={handleChange}  value={values.designation}/>
							} />
							<InputRow label="User Types*" input={
								<MSelect name="user_type" placeholder="Select" onChange={handleChange}  value={values.user_type}>
									<MenuItem disabled value={''}>Select</MenuItem>
									<MenuItem value={'licensed_user'} disabled={isDisabledPmLicensedOption}>PM / Corporate Licence User</MenuItem>
									<MenuItem disabled={isDisabledIndividualOption} value={'individual_user'} >Individual User</MenuItem>
								</MSelect>
							} />
							<InputRow label="Description" input={<MCountedTextArea name="description" placeholder="Write a description" onChange={handleChange}  value={values.description}/>} />
							<InputRow input={
								<MDatePicker name="subscription_date" disabled InputProps={{ placeholder: "Enter Date" }} disablePast />
							} label="Subscription end date" />
							<Flex justify="center" align="center" gap={10} style={{margin: '15px 0px'}}>
								<SubmitBtn
									variant="contained"
									onClick={this.closeModal}
									bcolor='#e0e0e0'
									tcolor='#fff'
								>
									CANCEL
								</SubmitBtn>
								<SubmitBtn
									tcolor='#fff'
									type="submit"
									variant="contained"
									bcolor='#009c05'
								>
									SUBMIT
								</SubmitBtn>
							</Flex>
							<Errormodal message={this.state.errMessage} handleErrPopover={this.handleErrPopover} errPopover={this.state.errPopover} />
						</AddUserWrapper>
					</FormAdd>
				)}
			</Formik>
		</AddUserBox>
	}

	EditUser() {
		const data=this.user?.attributes?.requester_account?.data?.attributes
		return <AddUserBox>
			<AddUserHead>
				EDIT USER
			</AddUserHead>
			<Divider light={true} />
			<Formik
				initialValues={{
					id: this.user?.id,
					first_name: data?.first_name,
					last_name: data?.last_name,
					email: data?.email,
					location: data?.location,
					company_name: data?.company_name,
					designation: data?.designation,
					prefrence: data?.preference,
					user_type: this.user?.attributes?.user_type,
					description: data?.about,
					subscription_date: this.state.subscriptionDetails?.end_date,
					is_active: this.user?.attributes?.is_active
				}}
				validate={values => {
					const errors: any = {};
					if (!/^[a-zA-Z\s]*$/.test(values.first_name)) {
						errors.first_name = 'Invalid name, numbers cannot be accepted';
					} if (!/^[a-zA-Z\s]*$/.test(values.last_name)) {
						errors.last_name = 'Invalid name, numbers cannot be accepted';
					}  if (!values?.first_name) {
						errors.first_name = 'Required';
					} if (!values?.last_name) {
						errors.last_name = 'Required';
					} 
					if (!values?.location) {
						errors.location = 'Required';
					} 
					if (!values?.user_type) {
						errors.user_type = 'Required';
					} 
					if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
						errors.email = 'Invalid email address';
					} 
					if (!/^[\s\S]{0,1000}$/i.test(values.description)) {
						errors.description = 'Character length should be in between 0-1000';
					} 
					if (this.state.prefrenceData?.length<=0) {
						errors.prefrence = 'Required';
					}
					return errors;
				}}
				onSubmit={(values) => {
					this.EditForm(values)
				}}
			>
				{({
					handleSubmit,
					isSubmitting,
					values,
					handleChange,
					setFieldValue
				}) => (
					<FormEdit style={{margin: '15px auto 0 auto', maxWidth: '650px'}} onSubmit={handleSubmit}>
						<AddUserWrapper>
						<InputRow label="" input={<Flex gap={10} style={{ width: '100%' }}>
									<InputRow style={{ width: '100%' }} label="First Name*" 
									input={
										<MInput name="first_name" placeholder="" onChange={handleChange}  value={values.first_name}/>
									}
									/>
									<InputRow
									label="Last Name*"
									 style={{ width: '100%' }}
									 input={
										<MInput name="last_name" placeholder="" onChange={handleChange}  value={values.last_name} />
									} />
								</Flex>
							} />
							<InputRow label="Email*" input={
								<MInput disabled name="email" placeholder="" onChange={handleChange}  value={values.email}/>
							} />
							<FieldSetWrapper xs={12} >
								<FieldLabel
									component="div"
									variant="subtitle2"
								>
									Preference <sup>*</sup>
								</FieldLabel>
								<Box style={{
									marginRight: '0px',
									margin: "10px 12px",
								}}>

									<MSelect
										displayEmpty
										id="prefrence"
										name="prefrence"
										multiple
										fullWidth
										onChange={(e) => this.handleChangePreference(e,setFieldValue)}
										renderValue={(selected) => (
											<Box 
												style={{ display: "flex", }}
											>
												<img src={image_preference} width="20px" height="15px" style={{ borderRadius: "10px", paddingTop: "4px", marginRight: "5px" }} />
												<Typography 
													component={'span'} 
													style={{ fontSize: "14px", margin: "0px", padding: "0" }}
												>
													Select
												</Typography>
											</Box>)
										}

										MenuProps={{
											getContentAnchorEl: null,
											anchorOrigin: {
												horizontal: "left",
												vertical: "bottom",
											},
											transformOrigin: {horizontal: "left",vertical: "top",}
										}}
									>
										<MenuItem 
										style={{padding: "20px 20px",height: "50px",}} 
										key="E" 
										value="Environment"
										>
											<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
												checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
												checked={values?.prefrence?.indexOf("Environment") > -1}
											 />
											<ListItemText disableTypography primary={
												<div style={{ display: "flex", justifyContent: "space-between" }}>
													<Typography>E</Typography>
													<Typography>#Environment</Typography>
													<Typography>

													</Typography>
												</div>} />
										</MenuItem>

										<MenuItem 
										style={{
											height: "50px",
											padding: "20px 20px",
										}} key="S" value="Social">
											<Checkbox
												icon={
												<CheckBoxOutlineBlankIcon fontSize="small" />
												}
												checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
												checked={values?.prefrence?.indexOf("Social") > -1} 
												/>
											<ListItemText disableTypography primary={
												<div style={{ display: "flex", justifyContent: "space-between" }}>
													<Typography>S</Typography>
													<Typography style={{ justifyContent: "start", marginRight: "45px" }}>#Social</Typography>
													<Typography>

													</Typography>

												</div>} />
										</MenuItem>
										<MenuItem key="G" style={{
											height: "50px",
											padding: "20px 20px",
										}} value="Governance">
											<Checkbox
												checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
												icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
												checked={values?.prefrence?.indexOf("Governance") > -1} 
												/>
											<ListItemText 
											disableTypography primary={
												<div style={{ display: "flex", justifyContent: "space-between" }}>
													<Typography>G</Typography>
													<Typography>#Governance</Typography>
													<Typography>

													</Typography>
												</div>} />
										</MenuItem>
									</MSelect>
								</Box>
								{values.prefrence?.map((prefrence: any) => {
									let value=prefrence
									if(prefrence=='1'){
										value= 'Environment'
									}
									if( prefrence=='2'){
										value= 'Governance'
									}
									if(prefrence=='3'){
										value= 'Social'
									}
									return <Chip label={value}
										key={value}
										onDelete={this.handleDelete(value,setFieldValue)}
										style={{ 
											margin: "5px", 
											marginLeft: '10px' 
										}}
									/>
								})}
							</FieldSetWrapper>
							 {/* @ts-ignore */}
							 <InputRow label="Location*" name="location" onChange={handleChange}  value={values.location} input={<><Maps autoCompleteStyles={undefined} customBorder={""} {...this.props} input  /></>} />

							<InputRow label="PM/Company Name" input={
								<MInput name="company_name" disabled placeholder="" />
							} />
							<InputRow label="Designation" input={
								<MInput name="designation" placeholder="" onChange={handleChange}  value={values.designation}/>
							} />
							<InputRow label="User Types*" input={
								<MSelect name="user_type" placeholder="Select" onChange={handleChange}  value={values.user_type}>
									<MenuItem disabled value={''}>Select</MenuItem>
									<MenuItem value={'licensed_user'} >PM / Corporate Licence User</MenuItem>
									<MenuItem value={'individual_user'}>Individual User</MenuItem>
								</MSelect>
							} />
							<InputRow label="Description" input={
								<MCountedTextArea name="description" placeholder="Write a description" onChange={handleChange}  value={values.description}/>
							} />
							<InputRow  label="Subscription end date" input={
								<MDatePicker  name="subscription_date" disabled InputProps={{ placeholder: "Enter Date" }} disablePast />
							} />
							<Flex justify="center" align="center" gap={10} style={{margin: '15px 0px'}}>
								<SubmitBtn
									variant="contained"
									bcolor='#e0e0e0'
									tcolor='#fff'
									onClick={this.closeModal}
								>
									CANCEL
								</SubmitBtn>
								<SubmitBtn
									type="submit"
									variant="contained"
									bcolor='#009c05'
									tcolor='#fff'
								>
									SAVE
								</SubmitBtn>
							</Flex>
							<Errormodal message={this.state.errMessage} handleErrPopover={this.handleErrPopover} errPopover={this.state.errPopover} />
						</AddUserWrapper>
					</FormEdit>
				)}
			</Formik>
		</AddUserBox>
	}

  	// Customizable Area End
	
	render() {
		// Customizable Area Start
		const { navigation } = this.props
		// Customizable Area End
		return (
			// Customizable Area Start
			<>
				<MainBox>
					<Box>
						<TopNavBar navigation={this.props.navigation} id={""} />
					</Box>

					<OuterGrid container spacing={3}>
						<LeftGrid item xs={12} sm={12} md={3} lg={3}>
							<Box >
								<LeftMenuWeb closeModal={this.closeModal} navigation={this.props.navigation} id={""} />
							</Box>
						</LeftGrid>
						<MiddleGrid item xs={12} sm={12} md={6} lg={6} >
							<OverlayLoader loading={this.state.overlayLoader} />
							{this.state.modal === "addnewuser" ?
								this.AddNewUser() : this.state.modal === "approveUser" ?
									this.ApproveUser() :
									this.state.modal === "editUser" ? this.EditUser()
										: this.PeopleManagementTabs()}
						</MiddleGrid>
					</OuterGrid>

				</MainBox>
			</>
			// Customizable Area End
		)
	}
}

// Customizable Area Start
PeopleManagementController.contextType = UserContext

export const Errormodal = ({ errPopover, handleErrPopover, message }: any) => {
	return <Modal
		open={errPopover}
		onClose={handleErrPopover}
	>
		<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
			<Box style={{ backgroundColor: '#fff', position: 'relative', padding: '30px 30px', borderRadius: '8px', textAlign: 'center', maxWidth: '500px', margin: '0 auto' }}>
				<Box onClick={handleErrPopover} style={{ position: 'absolute', right: '15px', top: '15px', color: '#fff', width: '20px', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px' }}>
					<CloseCircleIcon />
				</Box>
				<img src={warningIcon} alt='waning' style={{ width: "50px", marginBottom: "24px" }} />
				<Typography >
					{message}
				</Typography>
			</Box>
		</Box>
	</Modal>
}
interface FilterMenuProps {
	filterByAll: boolean,
	// filterByStatus: boolean,
	filterByPrefrence: boolean,
	// filterByFav: boolean,
	toggleFilterByAll: any,
	toggleFilterByPrefrence: any,
	// toggleFilterByStatus: any
	menuPosition: {
		top: number,
		left: number,
	} | undefined
	onClose: () => void,
	inside?: string
}

const FilterMenu = ({ menuPosition, onClose, ...props }: FilterMenuProps) => {
	const [nestedMenuName, setNestedMenuName] = React.useState("")
	const windowSize = useWindowSize()
	if (windowSize?.width < 1024) return <></>
	return (
		<>
			<Menu open={!!menuPosition} anchorPosition={menuPosition} onClose={onClose} anchorReference="anchorPosition">
				<MenuItem selected={props.filterByAll} onClick={() => props.toggleFilterByAll('All')}>
					All
				</MenuItem>
				{/* <NestedMenuItem
					name="status"
					nestedMenuName={nestedMenuName}
					setNestedMenuName={setNestedMenuName}
					label="Status"
					parentMenuOpen={!!menuPosition}
					// @ts-ignore
					MenuProps={{
						getContentAnchorEl: null,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
					}}
				>
					<FilterByDateWrapper style={{ height: 'auto', width: 'auto' }}>
						<MenuItem onClick={() => props.toggleFilterByStatus('yes', 'Active')} selected={props.filterByStatus}>
							Active
						</MenuItem>
						<MenuItem value={"no"} onClick={() => props.toggleFilterByStatus('no', 'Inactive')} selected={props.filterByStatus}>
							Inactive
						</MenuItem>
					</FilterByDateWrapper>
				</NestedMenuItem> */}

				<NestedMenuItem
					name="prefrence"
					nestedMenuName={nestedMenuName}
					setNestedMenuName={setNestedMenuName}
					label="Preferences"
					parentMenuOpen={!!menuPosition}
					// @ts-ignore
					MenuProps={{
						getContentAnchorEl: null,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
					}}
				>
					<FilterByDateWrapper style={{ height: 'auto', width: 'auto' }}>
						<MenuItem onClick={() => props.toggleFilterByPrefrence([1], 'Environment')} selected={props.filterByPrefrence}>
							Environment
						</MenuItem>
						<MenuItem onClick={() => props.toggleFilterByPrefrence([2], 'Social')} selected={props.filterByPrefrence}>
							Social
						</MenuItem>
						<MenuItem onClick={() => props.toggleFilterByPrefrence([3], 'Governance')} selected={props.filterByPrefrence}>
							Governance
						</MenuItem>
					</FilterByDateWrapper>
				</NestedMenuItem>
			</Menu>
		</>
	)
}

interface UserModalInterface {
	showModal: boolean
	handleModal: any
	updatePeopleManagementListing: any
	value: any
	rejectModal: boolean
	DeleteRow:any
}

export const UserModal = ({ showModal, rejectModal, handleModal, DeleteRow, updatePeopleManagementListing, value }: UserModalInterface) => {
	return (
		<Modal
			open={showModal}
			onClose={()=>handleModal(value)}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={showModal}>
				<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
					<Box style={{ backgroundColor: '#fff', position: 'relative', padding: '30px 30px', borderRadius: '8px', textAlign: 'center', maxWidth: '500px', margin: '0 auto' }}>
						<Box data-test-id='handleDeleteModal' onClick={()=>handleModal(value)} style={{ position: 'absolute', right: '15px', top: '15px', color: '#fff', width: '20px', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px' }}>
							<CloseCircleIcon />
						</Box>
						<Typography variant="h6" component="h2">
							<img src={warningIcon} alt='waning' style={{ width: "50px", marginBottom: "24px" }} />
						</Typography>
						<Typography >
							{rejectModal ? "Are you sure you want to reject the request ?" : "This user will be remove from your company. Are you sure you want to delete the user ?"}
						</Typography>
						<Box style={{ display: 'flex', marginTop: '20px', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
							<button
								data-test-id='removeData'
								onClick={() => {
									handleModal(value)
									{
										rejectModal ? DeleteRow(value,"yes") : DeleteRow(value,"no")
									}
								}}
								style={{ backgroundColor: 'green', color: '#fff', borderRadius: '5px', padding: '5px 20px', border: 'none' }}>Yes</button>
							<button data-test-id='closeModal' onClick={()=>handleModal(value)} style={{ backgroundColor: 'red', color: '#fff', borderRadius: '5px', padding: '5px 20px', border: 'none' }}>No</button>
						</Box>
					</Box>
				</Box>
			</Fade>
		</Modal>
	)
}

export const MouseOverPopover = ({ open, anchorEl, handleClose, value, editUSer, handleDeleteUser }: any) => {
	return (
			<Popper
				open={open}
				anchorEl={anchorEl}
				placement="bottom"
				style={{ borderRadius: '15px',boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px',background:'#FFFFFF'}}
			><ClickAwayListener onClickAway={handleClose}>
				<Box style={{padding: '10px', minWidth: '70px'}}>
					<Typography data-test-id='editUserFromList' onClick={() => {
						editUSer(value);
					}} style={{ cursor: "pointer", padding: "10px 0px 0px 10px", fontSize: "0.875rem", color: "rgba(0, 0, 0, 0.87)" }}>Edit</Typography>
					<Typography data-test-id='deleteUserFromList' style={{ cursor: "pointer", padding: "10px 0px 0px 10px", fontSize: "0.875rem" }} onClick={() => {
						handleDeleteUser()
					}}>Delete</Typography>
				</Box>
				</ClickAwayListener>
			</Popper>
	);
}

const MainBox = styled(Box)({
	backgroundColor: "#edebeb",
	display: "flex",
	flexWrap: 'wrap',
	flexDirection: "column",
	paddingBottom: "20px",
	overflowX: "hidden",
})

const OuterGrid = styled(Grid)({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
})

const TextFieldRounded = styled(TextField)({
	width: '100%',
	background: '#FFFFFF',
	borderRadius: '15px',
	'& > .MuiOutlinedInput-root': {
		borderRadius: '15px',
	},
	'& > div > input': {
		paddingTop: '14px',
		paddingBottom: '14px'
	}
})

const LeftGrid = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
		maxWidth: '200px',
	},
	'@media screen and (max-width: 768px)': {
		display: 'none',
		visibility: 'hidden',
	},
})

const MiddleGrid = styled(Grid)({
	width: 'calc(100vw - 300px)',
	maxWidth: 'unset',
	flexBasis: 'unset',
	paddingRight: '30px !important',
	justifyContent: 'center',
	'@media screen and (max-width: 768px)': {
		width: '100%'
	}
})

export const FieldSetWrapper = styled(Grid)({
	marginLeft:'-12px',
	'@media (max-width: 1470px)': {
		marginTop: '15px'
	}
}) as typeof Grid

export const FieldLabel = styled(Typography)({
	fontWeight: 600,
	color: "#404040",
	fontSize: '13px',
	marginLeft:'12px !important'
}) as typeof Typography

const LoaderContainer = styled('div')({
	margin: "auto",
	width: "100%",
	padding: "19px",
	display: "flex",
	justifyContent: "center"
})

const CoinsHead = styled('div')({
	margin: '40px 0px 15px 12px',
	fontSize: '1.2rem',
	fontFamily: 'HelveticaNeue',
	marginLeft: '0px',
	fontWeight: 600,
	'@media screen and (max-width: 768px)': {
		margin: '20px 0px 10px 12px',
	},
})

const FlexUser = styled(Flex)({
	'& .MuiInputBase-root.MuiOutlinedInput-root': {
		backgroundColor: '#fff',
	},
})



export const FormEdit = styled('form')({
	'& .Mui-disabled.Mui-disabled': {
		backgroundColor: '#eee',
	},
})

export const FormAdd = styled('form')({
	maxWidth: '650px',
	margin: '0 auto',
	'& .Mui-disabled.Mui-disabled': {
		backgroundColor: '#eee',
	},
})

const FilterByDateWrapper = styled('div')({
	width: '300px',
	height: '200px',
	padding: '10px 15px',
	background: '#FFF'
})


const CorpCoins = styled('div')((props: any) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	backgroundColor: 'fff',
	borderRadius: '12.97px',
	lineHeight: '20px',
	flex: 1,
	padding: '20px',
	gap: '20px',

	'@media screen and (max-width: 479px)': {
		flexDirection: 'column',
		alignItems: 'center',
		gap: '20px',
	},
	'@media (min-width:480px) and (max-width:767px)': {
		flexDirection: 'column',
		gap: '20px',
	},
	'@media screen and (max-width: 1200px)': {
		flexDirection: 'column',
		gap: '20px',
	},
}))


const TC = styled('div')({
	fontSize: '1rem',
	fontFamily: 'Helvetica',
	fontWeight: 600,
	lineHeight: '35px',
	textAlign: 'initial',
	'@media screen and (max-width: 479px)': {
		textAlign: 'center',
	},
	'@media screen and (min-width:768px) and (max-width: 985px)': {
		textAlign: 'center',
	},
})

const CorpsTable = styled('div')({
	backgroundColor: '#fff',
	borderRadius: "12.97px",
	padding: '10px'
})

const TH = styled(TableCell)({
	color: "#000",
	fontWeight: 600,
	lineHeight: "1.5rem",
	padding: '16px 10px',
	textAlign: 'center',
	position: 'relative',
})

const TR = styled(TableCell)({
	lineHeight: "1.5rem",
	padding: '16px 10px',
	textAlign: 'center'
})

const AR = styled('div')({
	width: '20px',
	// float: 'right',
	position: 'absolute',
	right: '30%',
	top: '8%'
})

const Pagin = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '10px',
	'@media screen and (max-width:350px)': {
		display: 'initial',
		padding: '10px 1px',
	},
	'@media screen and (min-width:768px) and (max-width:850px)': {
		display: 'initial',
		padding: '10px 1px',
	},
	'& > MuiPaginationItem-icon': {
		color: 'green',
	},

	ul: {
		"& > .MuiPaginationItem-root": {
			color: "green"
		}
	}
})


const CorporateLicenceTab = styled(Button)({
	border: "2px solid transparant",
	color: "#009B07",
	fontWeight: 600,
	backgroundColor: "#FFFFFF",
	textTransform: "none",
	borderRadius: "38px",
	height: "100%",
	padding: "0 45px 0 45px",
	'@media screen and (max-width: 1110px) and (min-width: 769px)': {
		padding: "0 35px 0 35px",
	},
	'@media screen and (max-width: 624px)': {
		padding: "0 35px 0 35px",
	},
	'@media screen and (max-width: 500px)': {
		padding: "0 25px 0 25px",
	}
});
const IndividualUserTab = styled(Button)({
	border: "2px solid transparant",
	color: "#009B07",
	fontWeight: 600,
	backgroundColor: "#FFFFFF",
	textTransform: "none",
	borderRadius: "38px",
	height: "100%",
	padding: "0 50px 0 50px",
	'@media screen and (max-width: 1110px) and (min-width: 769px)': {
		padding: "0 30px 0 30px",
	},
	'@media screen and (max-width: 624px)': {
		padding: "0 30px 0 30px",
	},
	'@media screen and (max-width: 500px)': {
		padding: "0 20px 0 20px",
	}
});
const PendingLicenceUserTab = styled(Button)({
	border: "2px solid transparant",
	color: "#009B07",
	fontWeight: 600,
	backgroundColor: "#FFFFFF",
	textTransform: "none",
	borderRadius: "38px",
	height: "100%",
	padding: "0 50px 0 50px",
	'@media screen and (max-width: 1110px) and (min-width: 769px)': {
		padding: "0 30px 0 30px",
	},
	'@media screen and (max-width: 624px)': {
		padding: "0 30px 0 30px",
	},
	'@media screen and (max-width: 500px)': {
		padding: "0 20px 0 20px",
	}
});

const PageWrapper = styled("div")({
	display: 'flex',
	justifyContent: 'center',
	minHeight: '100vh'
});
const CenterRow = styled("div")((props: any) => ({
	width: '100%',
}));

const RoundedButtonBase = styled('div')((props: any) => ({
	height: "47px",
	fontWeight: 700,
	display: 'flex',
	textAlign: 'center',
	justifyContent: 'center',
	backgroundColor: "#FFFFFF",
	flex: "wrap",
	color: "#009E00",
	borderRadius: '38px',
	border: " 2px solid #009B07"


}))

export const RoundedButtonNewUSer = styled(Button)({
	backgroundColor: '#009c05',
	color: 'white',
	borderRadius: '20px',
	'&:hover': {
		backgroundColor: '#009c05',
	}
})

const Action = styled('div')((props: any) => ({
	padding: '10px ',
	backgroundColor: props.color,
	color: "#FFFFFF",
	borderRadius: '10px',
	width: '70px',
	height: '22px',
}))

export const AddUserBox = styled('div')((props: any) => ({
	backgroundColor: 'fff',
	borderRadius: '12.97px',
	lineHeight: '20px',
	flex: 1,
	padding: '20px',
	gap: '60px',
	margin: '30px'
}))

const AddUserHead = styled('div')({
	margin: '15px',
	fontSize: '1.2rem',
	fontWeight: 600,
	textAlign: 'center',
	'@media screen and (max-width: 768px)': {
		margin: '20px 0px 10px 12px',
	},
})

const AddUserWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	gap: '20px'
})

const SubmitBtn = styled(Button)((props: any) => ({
	backgroundColor: props.bcolor,
	color: props.tcolor,
	padding: '10px 50px',
	borderRadius: '5px',
	width: '4rem',
	textAlign: 'center',
	'&:hover': {
		backgroundColor: ""
	}
}))
// Customizable Area End