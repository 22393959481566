import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { sendAPIRequest } from "../../../components/src/utils";
export const configJSON = require("./config");
export const QuickBlox = require('quickblox/quickblox.min').QuickBlox;
export const QB = new QuickBlox();
import generatePassword from 'generate-password';
import { toast } from 'react-toastify'
import { UserDetailType } from './types'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  txtInputValue: string;
  loading:boolean;
  searchInputValue:string;
  txtSavedValue: string;
  token: string;
  //   corpstageWallet: CorpstageWallet | null;
  modal: string | null;
  pages: number;
  errMessage:string;
  loadingEvent: boolean;
  current_page: number;
  total_pages: number;
  PageSortQuery: boolean;
  sortby: string;
  sortOrder: number | null;
  loadingCorpstageCoins: boolean,
  events: Event[];
  activeIndividualUserButton: boolean;
  activeChooseButton: boolean;
  corporateLicenseUser: boolean;
  individualUser: boolean;
  activePendingLicenceRequestButton: boolean;
  pendingLicenceReq: boolean;
  openModal: boolean;
  rowDeleted:boolean;
  openAddNewUserModal: boolean;
  errorModal: boolean;
  anchorEl: null | string | boolean;
  switchChecked: boolean;
  urlSearchParam: URLSearchParams;
  filterQuery: string;
  errPopover:boolean;
  prefrenceData: any[];
  table_row_id:string;
  showDeleteModal:boolean;
  subscriptionDetails: any;
  total_count:number;
  peopleManagementData: [{
      attributes: {
        requester_account: {
          data: {
            attributes: {
              first_name: string,
              last_name: string,
              email: string,
              location: string,
              company_name: string,
              designation: string,
             role: any,
              preference: []
            }
          }
        },
        user_type: string
      }
      id: string
    }]

  filterMenuPosition: {
    top: number,
    left: number,
  } | undefined;
  filterText: string;
  editForm:boolean;
  overlayLoader: boolean;
  filterByAll: boolean;
  filterByStatus: boolean;
  filterByPrefrence: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PeopleManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  subscriptionList: any
  peopleManagement: any
  updatedPeopleManagement: any
  user: any
  newUser: any
  filterUser:any
  filterRef?:any
  totalPage:any
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.GetAllCategoriesResponseMessage),
      getName(MessageEnum.GetAllSubCategoriesResponseMessage),
      getName(MessageEnum.NewServiceCreatedMessage),
      getName(MessageEnum.CorpstageCoinsUpdateMessage)
      // Customizable Area End
    ];


    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      editForm:false,
      showDeleteModal:false,
      searchInputValue:"",
      loading:false,
      txtSavedValue: "A",
      modal: null,
      errMessage:"",
      rowDeleted:false,
      events: [],
      token: '',
      PageSortQuery: false,
      sortOrder: null,
      sortby: '',
      overlayLoader:false,
      loadingCorpstageCoins: false,
      pages: 1,
      loadingEvent: false,
      current_page: 1,
      total_pages: 1,
      total_count:1,
      activeIndividualUserButton: false,
      activeChooseButton: true,
      corporateLicenseUser: true,
      individualUser: false,
      activePendingLicenceRequestButton: false,
      pendingLicenceReq: false,
      openModal: false,
      openAddNewUserModal: false,
      errorModal: false,
      anchorEl: null,
      switchChecked: true,
      urlSearchParam: new URLSearchParams(),
      filterQuery: '',
      errPopover:false,
      prefrenceData: [],
      subscriptionDetails: {},
      table_row_id:"",
      peopleManagementData:  [{
          attributes: {
            requester_account: {
              data: {
                attributes: {
                  first_name: "",
                  last_name: "",
                  email: "",
                  location: "",
                  company_name: "",
                  designation: "",
                  role: {},
                  preference: []
                }
              }
            },
            user_type: ""
          },
          id: ""
        }],
      filterMenuPosition: undefined,
      filterText: '',
      filterByAll: false,
      filterByStatus: false,
      filterByPrefrence: false,
      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.filterRef = React.createRef()
    // Customizable Area End
  }

  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

    if (apiRequestCallId === this.subscriptionList) {
          this.setState({ subscriptionDetails: responseJson })
      }
      else if (apiRequestCallId === this.peopleManagement) {
        this.setState({loading:false})
        this.setState({overlayLoader:false})
        this.setState({peopleManagementData:responseJson?.requests?.data})
        this.setState({current_page:responseJson?.meta?.pagination?.current_page})
        this.setState({total_pages:responseJson?.meta?.pagination?.total_count})
        this.setState({total_count:responseJson?.meta?.pagination?.total_pages})
      }
      else if (apiRequestCallId === this.updatedPeopleManagement) {
       this.userDataUpdated(responseJson)
      }
      else if (apiRequestCallId === this.newUser) {
        this.newUserAdded(responseJson)
      }
      else if (responseJson && responseJson.errors) {
        this.setState({overlayLoader:false})
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let authToken = await getStorageData("authToken")
    const userData = await getStorageData('user');
    const user = JSON.parse(userData);
    let isAllowed = user?.role?.role_level > 4 && user.user_type !== "licensed_user"
    if(!isAllowed){
      let message = "Sorry, You do not have access to this page"
      let page = "ActivityFeed"
      toast.warn(message)
      this.redirectionFun(page,this.props)    
    }
    this.setState({overlayLoader:true,token:authToken}, () => {
    this.getSubscriptionPackage()
    this.getPeopleManagementData('licensed_user', 'approved',this.state.pages)
    });
  }

  getCurrentTabData=()=>{
    if(this.state.corporateLicenseUser){
      this.getPeopleManagementData('licensed_user','approved',this.state.pages)
    }
    else if(this.state.individualUser){
      this.getPeopleManagementData('individual_user','approved',this.state.pages)
    }
    else{
      this.getPeopleManagementData('','pending',this.state.pages)
    }
  }

  newUserAdded=(responseJson:any)=>{
    this.setState({overlayLoader:false})
    if(responseJson && responseJson.errors){
      this.setState({errMessage:responseJson.errors[0]?.message || responseJson.errors})
      this.setState({errPopover:true})     
      this.parseApiErrorResponse(responseJson.errors);
      this.parseApiCatchErrorResponse(responseJson.errors);     
    }
    if (responseJson && !responseJson.errors) {
      this.getCurrentTabData()
      this.getSubscriptionPackage()
      this.setState({ modal: null })
    }
  }

  handlePageData=()=>{
    if(this.state.current_page===1){
      this.setState({pages:1, current_page:1})
    }  else{
      this.setState({pages:this.state.total_count-1, current_page:this.state.total_count-1} )
    }
  }

  userDataUpdated = (responseJson:any)=>{
    this.setState({ anchorEl: null })
    this.setState({overlayLoader:false})
    if(this.state.peopleManagementData.length <= 0 && this.state.rowDeleted){
     this.handlePageData()
    }
    if((this.state.peopleManagementData.length==1 ||this.state.peopleManagementData.length<=0)  && this.state.editForm){
      this.handlePageData()
    }
    if(responseJson && responseJson.error){
      this.setState({loading:false,rowDeleted:false,editForm:false})
      this.setState({errMessage:responseJson.error})
      this.setState({errPopover:true})
      this.parseApiErrorResponse(responseJson.errors);
      this.parseApiCatchErrorResponse(responseJson.errors);
    }

    if (responseJson && !responseJson.error) {
      this.setState({loading:false,rowDeleted:false,editForm:false})
      this.setState({ modal: null })
      this.getCurrentTabData()
      this.getSubscriptionPackage()
    }
  }

  getSubscriptionPackage = async() => {
    const token= await getStorageData("authToken")

    let url = `${configJSON.subscriptionDetail}`
    this.subscriptionList = sendAPIRequest(`${url}`, {
      method: "GET",
      headers: { 'token': token, 'content-type': 'appliction/json' },
    })
  }

  getPeopleManagementData = async(user_type: string, status: string,pages:any) => {
    const token= await getStorageData("authToken")
    let url = `${configJSON.getPeopleManagementData}`
    this.peopleManagement = sendAPIRequest(`${url}?status=${status}&user_type=${user_type}&page=${pages}&per_page=${10}`, {
      method: "GET",
      headers: { 'token': token, 'content-type': 'appliction/json' },
    })
  }
  
  DeleteRow = (value:any,flag:any) => {
  const arr=this.state.peopleManagementData.filter((row) => row.id !== this.state.table_row_id)
  this.setState({peopleManagementData:arr as any})
  this.setState({ anchorEl: null, rowDeleted:true })
  flag==='yes' && this.updatePeopleManagementListing(value?.id, '', value?.attributes?.requester_account?.data?.attributes.location, value?.attributes?.requester_account?.data?.attributes?.designation, value?.attributes?.requester_account?.data?.attributes?.about, 'rejected', '', value?.attributes?.requester_account?.data?.attributes?.preference, value?.attributes?.requester_account?.data?.attributes?.first_name, value?.attributes?.requester_account?.data?.attributes?.last_name)			
  flag=="no" && this.updatePeopleManagementListing(value?.id, value?.attributes?.user_type, value?.attributes?.requester_account?.data?.attributes?.location, value?.attributes?.requester_account?.data?.attributes?.designation, value?.attributes?.requester_account?.data?.attributes?.about, '', 'deleted', value?.attributes?.requester_account?.data?.attributes?.preference, value?.attributes?.requester_account?.data?.attributes?.first_name, value?.attributes?.requester_account?.data?.attributes?.last_name);
};

  EditForm = (values:any) => {
    if(values?.prefrence?.length>0){
    if (values.prefrence.indexOf('Environment') !== -1){
      values.prefrence[values.prefrence.indexOf('Environment')] = 1
    }
    if (values.prefrence.indexOf('Social') !== -1){
      values.prefrence[ values.prefrence.indexOf('Social')] = 2
    }
    if (values.prefrence.indexOf('Governance') !== -1){
      values.prefrence[ values.prefrence.indexOf('Governance')] = 3
    }
    }

    this.setState({ anchorEl: null, editForm:true });
    this.updatePeopleManagementListing(values?.id, values?.user_type, values?.location, values?.designation, values?.description, 'approved', values?.is_active, values?.prefrence, values?.first_name, values?.last_name);
    
  }

  updatePeopleManagementListing = async(id: string, user_type: string,location:string,designation:string,description:string, status: string, is_active: string,preference:any,first_name:string,last_name:string) => {
    this.state.modal==="editUser" && this.setState({overlayLoader:true});
    let arr=preference
    if(arr?.length>0){
    if (arr?.indexOf('Environment') !== -1){
      arr[arr?.indexOf('Environment')] = 1
    }
    if (arr?.indexOf('Social') !== -1){
      arr[ arr?.indexOf('Social')] = 2
    }
    if (arr?.indexOf('Governance') !== -1){
      arr[ arr?.indexOf('Governance')] = 3
    }
    }
    const token= await getStorageData("authToken")
    let url = `${configJSON.getPeopleManagementData}/${id}`
    const body = new FormData()
    body.append("people_management_id", id)
    body.append("user_type", user_type)
    body.append("is_active", is_active),
    body.append("status", status)
    body.append("location", location)
    designation!==null ? body.append("designation", designation) :  body.append("designation", "")
    description!==null ? body.append("about", description) : body.append("about", "")
    body.append("preference_ids",arr?.toString())
    body.append("first_name", first_name)
    body.append("last_name", last_name)
    body.append("created_by", this.context?.id)

    this.updatedPeopleManagement = sendAPIRequest(`${url}`, {
      method: "PATCH",
      body,
      headers: { 'token': token },
    });
  }

  createUser = (value: any) => {
    this.setState({overlayLoader:true})
    const arr=value?.prefrence
    if(arr?.length>0){
    if (arr.indexOf('Environment') !== -1){
      arr[arr.indexOf('Environment')] = 1
    }
    if (arr.indexOf('Social') !== -1){
      arr[ arr.indexOf('Social')] = 2
    }
    if (arr.indexOf('Governance') !== -1){
      arr[ arr.indexOf('Governance')] = 3
    }
    }
    let userDetail = {
      email: value?.email,
      first_name: value?.first_name,
      last_name: value?.last_name,
      password: value?.password,
      preference_ids: arr,
      location: value?.location,
      about: value?.description,
      designation: value?.designation,
      user_type: value?.user_type,
      created_by: this.context?.id
    }
    this.createLicenceUser(userDetail)
  }
  createLicenceUser = async(userDetail:UserDetailType) => {
    const QB_AUTH_KEY = configJSON.QuibloxCred.QUICKBLOX_AUTH_KEY;
    const QB_APPLICATION_ID = configJSON.QuibloxCred.QUICKBLOX_APPLICATION_ID;
    const QB_AUTH_SECRET = configJSON.QuibloxCred.QUICKBLOX_AUTH_SECRET;
    const QB_ACCOUNT_KEY = configJSON.QuibloxCred.QUICKBLOX_ACCOUNT_KEY;
    
    let thisvar = this;
    QB.init(QB_APPLICATION_ID, QB_AUTH_KEY, QB_AUTH_SECRET, QB_ACCOUNT_KEY);
    await thisvar.timeOut(3000)
    QB.createSession((error:any, result:any) => this.quickBloxCreateSession(error, result,thisvar,userDetail));
  };
  quickBloxCreateSession = async(error:any, result:any,thisvar:any,userDetail:UserDetailType) => {
    if (error) {
      this.setState({overlayLoader:false});
     toast.error("User Already Exist");
    }
    const randomPassword =  this.generateRandomPassword();
    await thisvar.timeOut(3000)
    this.createUserWithQuickBlox(thisvar, randomPassword,userDetail);

  }
  timeOut = (ms:number) => new Promise((resolve) => setTimeout(resolve, ms));
  generateRandomPassword = () => {
    const passwordOptions = {
      length: 12, 
      numbers: true,
      symbols: false,
      uppercase: true,
      excludeSimilarCharacters: true,
      strict: true,
    };
    const password = generatePassword.generate(passwordOptions);
    return "id" + password;
  }
  createUserWithQuickBlox = async(thisvar:any, password:string,userDetail:UserDetailType) => {
    const { email, first_name, last_name } = userDetail;
    const fullName = `${first_name} ${last_name}`;
  
    const userQBParams = {
      login: email,
      password: password,
      full_name: fullName
    };
    QB.users.create(userQBParams, (error:any, result:any) => this.quickBloxCreateuser(error, result,thisvar,password,userDetail));
  };
  quickBloxCreateuser = async (error:any, result:any,thisvar:any,password:string,userDetail:UserDetailType) => {
    if (error) {
      this.setState({overlayLoader:false});
     toast.error("User Already Exist");
    }
    const { email } = userDetail;
    let body:any = { 
      ...userDetail,
      qb_id: result?.id,
      qb_pass: password,
      qb_login: email,
    };
    const token= await getStorageData("authToken")

    this.newUser = sendAPIRequest(`${configJSON.createUserPeopleManagement}`, {
      method: "POST",
      body,
      headers: { 'token': token, 'Content-Type': 'application/json' },
    });
    
  }

  chooseCorporateLicenseUser = (e:any) => {
    this.setState({searchInputValue:""})
    this.onFilterQueryChange("","")
    this.clearFilters(e)
    this.setState({loading:true})
    this.setState({ activeIndividualUserButton: false })
    this.setState({ activeChooseButton: true })
    this.setState({ corporateLicenseUser: true });
    this.setState({ individualUser: false });
    this.setState({ pendingLicenceReq: false });
    this.setState({ activePendingLicenceRequestButton: false });
    this.setState({ pages: 1 ,loading:true}, ()=>this.getPeopleManagementData("licensed_user",'approved', this.state.pages))
  }
  chooseIndividualUser = (e:any) => {
    this.setState({searchInputValue:""})
    this.onFilterQueryChange("","")
    this.clearFilters(e)
    this.setState({loading:true})
    this.setState({ activeChooseButton: false })
    this.setState({ activeIndividualUserButton: true })
    this.setState({ corporateLicenseUser: false });
    this.setState({ individualUser: true });
    this.setState({ pendingLicenceReq: false });
    this.setState({ activePendingLicenceRequestButton: false });
    this.setState({ pages: 1 ,loading:true}, ()=>this.getPeopleManagementData("individual_user",'approved', this.state.pages))
    console.log("History called...")
  }

  choosePendingLicenceReq = (e:any) => {
    this.setState({searchInputValue:""})
    this.onFilterQueryChange("","")
    this.clearFilters(e)
    this.setState({loading:true})
    this.setState({ activeChooseButton: false })
    this.setState({ activeIndividualUserButton: false })
    this.setState({ corporateLicenseUser: false });
    this.setState({ individualUser: false });
    this.setState({ pendingLicenceReq: true });
    this.setState({ activePendingLicenceRequestButton: true });
    this.setState({ pages: 1 ,loading:true}, ()=>this.getPeopleManagementData('','pending', this.state.pages))
  }

  changeModalScreen = () => {
    this.setState({prefrenceData:[]})
    this.setState({ modal: "addnewuser" })
  }

  changeToApproveUser = (getValues: any) => {
    this.setState({table_row_id:getValues.id})
    this.user = getValues
    this.setState({ modal: "approveUser" })
  }

  editUser = (getValues: any) => {
    this.user = getValues
    this.setState({prefrenceData:getValues?.attributes?.requester_account?.data?.attributes?.preference})
    this.setState({ anchorEl: null })
    this.setState({ modal: "editUser" })
  }
  closeModal = () => {
    this.setState({ modal: null })
  }

  handlePageChange = (event: any, value: any) => {
   let preferences:any='';
   this.state.filterText==="Social" && (preferences= 2) ;
   this.state.filterText==="Environment" && (preferences= 1) ;
   this.state.filterText==="Governance" && (preferences=3);
   let is_active= ''
   this.state.filterText==="Active" && (is_active='yes') 
   this.state.filterText==="Inactive" && (is_active='no');
    if(preferences==1 || preferences==2 || preferences==3) {
     this.setState({ pages: value ,loading:true},()=>this.getPeopleManagement(value,'',preferences)) ;
    }
    else if(is_active=='yes' || is_active=="no") {
      this.setState({ pages: value ,loading:true}, ()=>this.getPeopleManagement(value,is_active,''))
    }
    else{
      this.setState({ pages: value ,loading:true},()=>this.getPeopleManagement(value,'',''))
    }
  }

  handleModal = (value:any) => {
    this.setState({table_row_id:value?.id})
    this.setState({ openModal: !this.state.openModal })
    this.setState({showDeleteModal: !this.state.showDeleteModal})
  }


  handleClick = (event: any,id:string) => {
    this.setState({table_row_id:id})
    this.setState({ anchorEl: event.currentTarget })
  };

  handleClose = (event: Event) => {
    this.setState({ anchorEl: null })
  };


  getFilteredPeopleManagementList = async(queryParams:string,preferences:any,is_active:any) => {
    const token= await getStorageData("authToken")
    let status=this.state.pendingLicenceReq ? 'pending' : 'approved'
    // url=is_active ? `${configJSON.getPeopleManagementData}?status=${status}&user_type=${user_type}&search_query=${queryParams}&is_active=${is_active}`
    let user_type=this.state.corporateLicenseUser ? "licensed_user" : this.state.individualUser ? 'individual_user' : ''

    let url = preferences ?`${configJSON.getPeopleManagementData}?status=${status}&user_type=${user_type}&search_query=${queryParams}&preference_ids[]=${preferences}` :
    `${configJSON.getPeopleManagementData}?status=${status}&user_type=${user_type}&search_query=${queryParams}`
   
    this.peopleManagement = sendAPIRequest(`${url}`, {
      method: "GET",
      headers: { 'token': token, 'content-type': 'appliction/json' },
    })
  }

  onFilterChange = (filter: string,preferences:any) => {
    const preferenceData= (preferences===1 || preferences===2 || preferences===3) && preferences 
    const is_active= (preferences==="yes" || preferences==="no") && preferences 
    if(filter === this.state.filterQuery) return
    this.setState({ filterQuery: filter })
    let queryParams=filter.toString()
    let str=queryParams.includes('+') ? queryParams.replace('+','%2B') 
    : queryParams.includes('-') ? queryParams.replace('-','%2D')
    : queryParams.includes('!') ? queryParams.replace('!','%20')
    : queryParams.includes('$') ? queryParams.replace('$','%24')
    : queryParams.includes('&') ? queryParams.replace('&','%26')
    : queryParams.includes('#') ? queryParams.replace('#','%23')
    : queryParams.includes('%') ? queryParams.replace('%','%25')
    : queryParams.includes('(') ? queryParams.replace('(','%28')
    : queryParams.includes(')') ? queryParams.replace(')','%29')
    : queryParams
      this.getFilteredPeopleManagementList(str,preferenceData,is_active)
      this.setState({pages:1,current_page:1})
  }

  onFilterQueryChange = (query: any,preferences:any) => {
    this.setState({searchInputValue:query})
    this.onFilterChange(query,preferences)
  }

  handleFilterMenuClose = () => {
    this.setState({ filterMenuPosition: undefined })
  }

  handleFilterMenuOpen = () => {
    if (!this.filterRef) return;
    const box = this.filterRef?.current?.getBoundingClientRect();
    this.setState({
      filterMenuPosition: {
        top: box?.bottom,
        left: box?.left + 20
      }
    })
  }

  getBlankFilters = () => {
    return {
      filterByAll: false,
      filterByStatus: false,
      filterByPrefrence: false,
    }
  }

  getPeopleManagement = async(page:any,is_active?:any,preference?:any) => {
    const token= await getStorageData("authToken")
    let status=this.state.pendingLicenceReq ? 'pending' : 'approved';
    let user_type=''
    this.state.corporateLicenseUser && (user_type="licensed_user") ;
    this.state.individualUser && (user_type='individual_user');
    if(preference==1 || preference==2 || preference==3) { 
      this.peopleManagement = sendAPIRequest(`${configJSON.getPeopleManagementData}?status=${status}&user_type=${user_type}&search_query=${this.state.searchInputValue}&preference_ids[]=${preference}&page=${page}&per_page=${10}`, {
      method: "GET",
      headers: { 'token': token, 'content-type': 'appliction/json' },
    })}
    else if (is_active=="yes" || is_active=="no") {
      this.peopleManagement = sendAPIRequest(`${configJSON.getPeopleManagementData}?status=${status}&user_type=${user_type}&page=${page}&search_query=${this.state.searchInputValue}&is_active=${is_active}&per_page=${10}`, {
      method: "GET",
      headers: { 'token': token, 'content-type': 'appliction/json' },
    })} 
    else{
      this.peopleManagement = sendAPIRequest(`${configJSON.getPeopleManagementData}?status=${status}&user_type=${user_type}&search_query=${this.state.searchInputValue}&page=${page}&per_page=${10}`, {
      method: "GET",
      headers: { 'token': token, 'content-type': 'appliction/json' },
    })
  }
  }

  toggleFilterByAll = (value:string) => {
    const filters = this.getBlankFilters()
    filters['filterByAll'] = !this.state.filterByAll
    this.setState({filterText:value,pages:1,current_page:1})
    this.setState({ filterMenuPosition: undefined})
    this.handleFilterMenuClose()
    this.getPeopleManagement(value,'','')
  }

  toggleFilterByPrefrence = (prefrences:any,value:string) => {
    this.setState({loading:true})
    const filters = this.getBlankFilters()
    filters['filterByPrefrence'] = !this.state.filterByPrefrence
    this.setState({filterText:value,pages:1,current_page:1})
    this.setState({ filterMenuPosition: undefined})
    this.handleFilterMenuClose()
    this.getPeopleManagement(value,'',prefrences)
  }

  // toggleFilterByStatus = (is_active:string,value:string) => {
  //   this.setState({loading:true})
  //   const filters = this.getBlankFilters()
  //   filters['filterByPrefrence'] = !this.state.filterByPrefrence
  //   this.setState({filterText:value,pages:1,current_page:1})
  //   this.handleFilterMenuClose()
  //   this.getPeopleManagement(value,is_active,'')
  // }

  clearFilters = (e: React.MouseEvent) => {
    e?.stopPropagation()
    this.setState({
      filterText: '',
      filterByAll: false,
      filterByStatus: false,
      filterByPrefrence: false,
      pages:1,
      current_page:1
    })
    // load posts with no filters
    this.getPeopleManagement(1,'','')
  }

  handleErrPopover = () => {
    this.setState({errMessage:""})
    this.setState({errPopover:false})
  }

  handleMultiSelect = (event: any) => {
    const {
      target: { value },
    } = event;
    this.setState({ prefrenceData:  value});
  };

  handleDeleteUser = () => {
    this.setState({showDeleteModal:!this.state.showDeleteModal})
  }

  handleChangePreference = (chip:any,setFieldValue:any) => {
    this.setState({
      prefrenceData: chip?.target?.value //shorthand way to copy an array and add a new element
    },()=>{setFieldValue("prefrence",this.state.prefrenceData)});
  };
  
  handleDelete = (chipToDelete: any,setFieldValue:any) => () => {
    console.log("chipToDelete",chipToDelete)
    this.setState({
      prefrenceData: this.state.prefrenceData.filter(c => c !== chipToDelete) //filter will produce a copy of the array
    },()=>{setFieldValue("prefrence",this.state.prefrenceData)});
  };

  redirectionFun = (route: string, props: Props) => {
    const messageData = new Message(getName(MessageEnum.NavigationMessage));
    messageData.addData(getName(MessageEnum.NavigationTargetMessage), route);
    messageData.addData(getName(MessageEnum.NavigationPropsMessage), props);   
    this.send(messageData);
  }

  // Customizable Area End
}

